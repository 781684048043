<script setup>
import { ref, computed, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import ui_btn_1 from "../../ui/ui_btn_1.vue";
import icon_trx from "@/components/ui/icon/icon_trx";
import icon_usdt from '@/components/ui/icon/icon_usdt';
import Iput2 from "../../ui/ui_input_2.vue";
import { WALLET_TOKEN_TYPE, transferTokenTo, getTransferTokenOrders } from "../../../utils/walletTools";
import common from "../../../utils/common";
import list_items from "./SendTokenItems.vue";

const store = useStore();
const langs = computed(() => {
  if (store.state.lang === "en") {
    return {
      usdtBalance: "USDT Balance",
      trxBalance: "TRX Balance",

      selectToken: "Token",
      toTitle: "To",
      toPlaceholder: "Please enter the acceptance address",
      amount: "Amount",
      amountPlaceholder: "Please enter the amount to be sent",
      send: "Send",

      tips1: "Tip: Each TRX transfer consumes 269 Bandwidth. If the bandwidth is insufficient, TRX will be deducted as a handling fee.",
      tips2: "Tip: 269 Bandwidth = 0.269 TRX。",
      tips3: "Tip: Each USDT transfer consumes 345 Bandwidth. If the recipient's address has USDT, it will consume 31895 Energy; If not, it will consume 64895 Energy. If the bandwidth and Energy are insufficient, TRX will be deducted as a handling fee.",
      tips4: "Tip: 345 Bandwidth = 0.345 TRX、31895 Energy ≈ 13.7 TRX、64895 Energy ≈ 17.16TRX。",
      tips5: "Tip: If there is insufficient energy, renting energy can save on transaction fees.",
      tips6: "Tip: There is a possibility of delay and failure in on chain transactions. You can go to the blockchain browser to view transaction details.",
      tips7: "Tip: If the recipient address is not activated, an additional 10 TRX will be consumed to activate the recipient address.",

      platform1: "Rental Platform 1",
      platform2: "Rental Platform 2",
      chainWeb: "Blockchain Browser",

      tipsSuccess: "The transaction request has been submitted!",
      address: "Invalid Tron address!",
    }
  } else {
    return {
      usdtBalance: "USDT餘額",
      trxBalance: "TRX餘額",

      selectToken: "鏈幣種類",
      toTitle: "接收方錢包地址",
      toPlaceholder: "請輸入錢包地址",
      amount: "發送數量",
      amountPlaceholder: "請輸入發送數量",
      send: "發送",

      tips1: "提示：每轉一筆TRX會消耗 269 頻寬。 如果頻寬不足，會扣除TRX作為手續費。",
      tips2: "提示：269 頻寬 = 0.269 TRX。",
      tips3: "提示：每轉一筆USDT會消耗 345 頻寬。 如果收款方地址有USDT，會消耗 31895 能量； 如果沒有，會消耗 64895 能量。 如果頻寬和能量不足，會扣除TRX作為手續費。",
      tips4: "提示：345 頻寬 = 0.345 TRX、31895 能量 ≈ 13.4 TRX、64895 能量 ≈ 27.3 TRX。",
      tips5: "提示：如果能量不足，租賃能量能够節省手續費。",
      tips6: "提示：鏈上交易有延時和失敗的可能，您可以前往區塊鏈瀏覽器查看交易詳情。",
      tips7: "提示：如果對方地址沒有激活，將額外消耗 1 TRX用於激活對方地址。",

      platform1: "租賃平臺1",
      platform2: "租賃平臺2",
      chainWeb: "區塊鏈瀏覽器",

      tipsSuccess: "交易請求已經提交！",
      address: "無效的Tron地址！",
    }
  }
});

//定于余额
const usdtBalance = defineModel("usdt");
const trxBalance = defineModel("trx");
defineProps(["addressUrl"]);

//选中发送的币
const curToken = ref(WALLET_TOKEN_TYPE.USDT);

//定于输入
const inputToAddress = ref("");
const inputAmount = ref("");
const orders = ref([]);

const disClick = computed(() => {
  if (inputToAddress.value === "" || inputAmount.value === "") {
    return true;
  }

  if (curToken.value === WALLET_TOKEN_TYPE.USDT && parseFloat(inputAmount.value) > parseFloat(usdtBalance.value)) {
    return true;
  }

  if (curToken.value === WALLET_TOKEN_TYPE.TRX && parseFloat(inputAmount.value) > parseFloat(trxBalance.value)) {
    return true;
  }

  return false;
});

//提交转账请求
async function requestTranser() {
  const reg = /^T[A-z0-9]{33}$/;
  if (!reg.test(inputToAddress.value)) {
    toastr['error'](langs.value.address);
    return;
  }

  try {
    common.showLoading("transfer token");
    await transferTokenTo(curToken.value, inputToAddress.value, inputAmount.value);
    toastr['success'](langs.value.tipsSuccess);

    inputAmount.value = "";
    //inputToAddress.value = "";
  } catch (error) {
    toastr['error'](error.message);
  }
  common.hideLoading("transfer token");
}

//获取转账订单信息
async function loadOrders() {
  try {
    orders.value = await getTransferTokenOrders();
  } catch (error) {
    toastr['error'](error.message);
  }
}

let timer = null;
onMounted(() => {
  loadOrders();
  timer = setInterval(loadOrders, 10000);
})

onUnmounted(() => {
  clearInterval(timer);
})

</script>
<style lang="scss" src="./SendToken.scss"></style>
<template>
  <div class="send_usdt">
    <!--余额-->
    <div class="block item_balance">

      <!--余额usdt-->
      <div>
        <h2>{{ langs.usdtBalance }}</h2>
        <div class="item_balance_content">
          <div class="token_bg">
            <icon_usdt />
          </div>
          <div class="item_balance_count">{{ usdtBalance }}</div>
        </div>
      </div>

      <!--余额trx-->
      <div>
        <h2>{{ langs.trxBalance }}</h2>
        <div class="item_balance_content">
          <div class="token_bg">
            <icon_trx />
          </div>
          <div class="item_balance_count">{{ trxBalance }}</div>
        </div>
      </div>
    </div>

    <!--用户输入-->
    <div class="template u_chain block">
      <div class="main">

        <!--链币种类选择-->
        <dl>
          <dt>{{ langs.selectToken }}</dt>
          <dd :class="{ on: curToken === WALLET_TOKEN_TYPE.USDT }" @click="curToken = WALLET_TOKEN_TYPE.USDT">
            <span>
              <icon_usdt />&nbsp;USDT
            </span>
          </dd>
          <dd :class="{ on: curToken === WALLET_TOKEN_TYPE.TRX }" @click="curToken = WALLET_TOKEN_TYPE.TRX">
            <span>
              <icon_trx />&nbsp;TRX
            </span>
          </dd>
        </dl>

        <!--接受方地址-->
        <dl>
          <dt>{{ langs.toTitle }}</dt>
          <dd class="inputbox">
            <Iput2 :placeholder="langs.toPlaceholder" width="100%" :backgroundcolor="'#1B203F'" :heigth="'.48rem'"
              v-model="inputToAddress" type="text" />
          </dd>
        </dl>

        <!--转账数量-->
        <dl>
          <dt>{{ langs.amount }}</dt>
          <dd class="inputbox">
            <Iput2 :placeholder="langs.amountPlaceholder" width="100%" :backgroundcolor="'#1B203F'" :heigth="'.48rem'"
              v-model="inputAmount" :type="'number1'" />
            <icon_trx v-if="curToken === WALLET_TOKEN_TYPE.TRX" class="icon" />
            <icon_usdt v-else class="icon" />
          </dd>
        </dl>

        <br>
        <div class="btnbox">
          <ui_btn_1 :width="'8rem'" :height="'0.48rem'" :disclick="disClick" @click="requestTranser">
            {{ langs.send }}
          </ui_btn_1>

          <!--提示信息-->
          <div class="hr"></div>
          <template v-if="curToken === WALLET_TOKEN_TYPE.TRX">
            <p class="tips"><span>*</span> {{ langs.tips1 }}</p>
            <p class="tips"><span>*</span> {{ langs.tips2 }}</p>
            <p class="tips"><span>*</span> {{ langs.tips7 }}</p>
          </template>
          <template v-else>
            <p class="tips"><span>*</span> {{ langs.tips3 }}</p>
            <p class="tips"><span>*</span> {{ langs.tips4 }}</p>
            <p class="tips">
              <span>*</span>
              {{ langs.tips5 }}
              &nbsp;
              <a target="_blank" href="https://www.trxneng.com/">{{ langs.platform1 }}</a>
              &nbsp;
              <a target="_blank" href="http://diditron.com/home">{{ langs.platform2 }}</a>
            </p>
          </template>
          <p class="tips">
            <span>*</span>
            {{ langs.tips6 }}
            &nbsp;
            <a target="_blank" :href="addressUrl">{{ langs.chainWeb }}</a>
          </p>
        </div>
      </div>

    </div>

    <!--历史记录-->
    <div class="block">
      <list_items :infos="orders"></list_items>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.template.u_chain {
  padding: 0.275rem 0.6rem;
  min-height: 100vh;
  min-height: auto;
}

#list_orders {

  dt,
  dd {
    display: flex;
    flex-direction: row;

    span {
      flex: 1;
    }
  }
}

@media screen and (max-width: 1059px) {
  .template.u_chain {
    .main {
      width: 100%;
    }
  }
}
</style>