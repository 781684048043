<template>
    <div class="template ui_filter">
        <div class="value" @click="showOptionsTag = !showOptionsTag">
            <span>{{ lang=='en'?'Type':'類型'}}</span><Icon_filter class="i" />
        </div>
        <ul v-show="showOptionsTag">
            <li v-for="(item, index) in props.optionList" :key="index" @click="clickOption(item.value)">
                <Icon_checkbox :class="{i:true,checked: modelValue==item.value}" /><span>{{item.name}}</span>
            </li>
        </ul>
    </div>
</template>
<script setup>
import { computed,defineProps,ref } from 'vue';
import { useStore } from 'vuex';
import Icon_filter from './icon/icon_filter.vue';
import Icon_checkbox from './icon/icon_checkbox.vue';
let store = useStore(); 
let lang = computed(() => {
    return store.state.lang;
});

let props = defineProps({
    modelValue: {
        default: 0
    },
    optionList:{
        type: Array,
        default(){
            return  [
                {
                    value: 0,
                    name:'全部'
                },
                {
                    value: 1,
                    name:'a'
                },
            ]
        }
    }
});
let emits = defineEmits(['update:modelValue']);

const clickOption = (v)=>{
    emits('update:modelValue',v);
    showOptionsTag.value = !showOptionsTag.value;
}

let showOptionsTag = ref(false);

</script>
<style lang="scss" scoped>
.template.ui_filter{
    position: absolute;
    top: 0;
    left: 0;
    font-size: inherit;
    width: 100%;
    .value{
        display: flex;
        font-size: inherit;
        cursor: pointer;
        .i{
            margin-left: 0.05rem;
        }
    }
    ul{
        position: absolute;
        width: 100%;
        top: 0.5rem;
        background-color: #303265;
        li{
            display: flex;
            font-size: inherit;
            align-items: center;
            cursor: pointer;
            height: 0.36rem;
            .i{
                margin-right: 0.1rem;
                &.checked {
                    :deep(svg){
                        display: none;
                        &.selected{
                            display: inline-block;
                        }
                    }
                }
            }
        }
    }
}
</style>