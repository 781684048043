<script setup>
import { ref, computed, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import ui_btn_1 from "../../ui/ui_btn_1.vue";
import icon_trx from "@/components/ui/icon/icon_trx";
import icon_usdt from '@/components/ui/icon/icon_usdt';
import icon_gold from '@/components/ui/icon/icon_gold';
import Iput2 from "../../ui/ui_input_2.vue";
import list_items from "./Gold2TokenItems";
import common from "../../../utils/common";
import { WALLET_TOKEN_TYPE, exchangeTokenFromGold, getExchangeTokenOrders } from "../../../utils/walletTools";

const props = defineProps(["goldBalance", "addressUrl", "trxUsdtPrice", "addressActivated"]);
const usdtBalance = defineModel("usdt");

const store = useStore();
const langs = computed(() => {
  if (store.state.lang === "en") {
    return {
      goldBalance: "Gold Balance",

      selectToken: "Token",
      amount: "Amount",
      amountPlaceholder: "Please enter the amount to be exchange",
      exchange: "Exchange",

      tips1: "Tip: Each TRX transfer consumes 269 Bandwidth. If the bandwidth is insufficient, TRX will be deducted as a handling fee.",
      tips2: "Tip: 269 Bandwidth = 0.269 TRX。",
      tips3: "Tip: Each USDT transfer consumes 345 Bandwidth. If the recipient's address has USDT, it will consume 31895 Energy; If not, it will consume 64895 Energy. If the bandwidth and Energy are insufficient, TRX will be deducted as a handling fee.",
      tips4: "Tip: 345 Bandwidth = 0.345 TRX、31895 Energy ≈ 13.7 TRX、64895 Energy ≈ 17.16TRX。",
      tips6: "Tip: here is a possibility of delay and failure in on chain transactions. You can go to the blockchain browser to view transaction details.",
      tips7: "Tip: The platform does not earn user transaction fees for this transaction, but the platform will deduct some gold as gas for on chain transactions.",
      tips8: "Tip: If your address is not activated, an additional 10 TRX will be consumed to activate the address.",

      chainWeb: "Blockchain Browser",

      getTokenAmout: "Amount of token obtained",
      chargeGold: "Estimated gas",
      totalGold: "Estimated total consumption of gold",
      tipsSuccess: "The exchange request has been submitted!",

      tipsUseTrx: "Recommend using TRX",
    }
  } else {
    return {
      goldBalance: "金幣餘額",

      selectToken: "獲得鏈幣種類",
      amount: "消耗金幣數量",
      amountPlaceholder: "請輸入兌換數量",
      exchange: "兌換",

      tips1: "提示：每轉一筆TRX會消耗 269 頻寬。 如果頻寬不足，會扣除TRX作為手續費。",
      tips2: "提示：269 頻寬 = 0.269 TRX。",
      tips3: "提示：每轉一筆USDT會消耗 345 頻寬。 如果收款方地址有USDT，會消耗 31895 能量； 如果沒有，會消耗 64895 能量。 如果頻寬和能量不足，會扣除TRX作為手續費。",
      tips4: "提示：345 頻寬 = 0.345 TRX、31895 能量 ≈ 13.4 TRX、64895 能量 ≈ 27.3 TRX。",
      tips6: "提示：鏈上交易有延時和失敗的可能，您可以前往區塊鏈瀏覽器查看交易詳情。",
      tips7: "提示：此次交易平臺不賺用戶手續費，但平臺會扣除部分金幣作為鏈上交易的燃油費。",
      tips8: "提示：如果您的地址沒有激活，將額外消耗 1 TRX用於激活地址。",

      chainWeb: "區塊鏈瀏覽器",

      getTokenAmout: "預計獲得鏈幣數量",
      chargeGold: "預計燃油費",
      totalGold: "預計消耗金幣總數",
      tipsSuccess: "兌換請求已經提交！",

      tipsUseTrx: "推薦使用TRX",
    }
  }
});

//选中发送的币
const curToken = ref(WALLET_TOKEN_TYPE.USDT);

//定于输入
const inputAmount = ref("");
const orders = ref([]);
const toAmount = computed(() => {
  const goldAmount = parseInt(inputAmount.value);
  if (isNaN(goldAmount)) {
    return 0;
  }

  let temp = 0;
  if (curToken.value === WALLET_TOKEN_TYPE.USDT) {
    temp = goldAmount / 1000;
  } else {
    temp = goldAmount / 1000 / props.trxUsdtPrice;
  }

  return parseFloat(temp.toFixed(6));
});

const chargeAmount = computed(() => {
  if (toAmount.value === 0) {
    return 0;
  }

  let temp = 0;
  if (curToken.value === WALLET_TOKEN_TYPE.USDT) {
    if (usdtBalance.value === 0) {
      temp = 27.7 * props.trxUsdtPrice * 1000;
    } else {
      temp = 13.7 * props.trxUsdtPrice * 1000;
    }
  } else {
    if (props.addressActivated) {
      temp = 0.269 * props.trxUsdtPrice * 1000;
    } else {
      temp = 1.269 * props.trxUsdtPrice * 1000;
    }
  }

  return Math.floor(temp);
});

const totalGoldAmount = computed(() => {
  const goldAmount = parseInt(inputAmount.value);
  if (isNaN(goldAmount)) {
    return 0;
  }

  return chargeAmount.value + goldAmount;
});

const disClick = computed(() => {
  console.log("parseInt(inputAmount.value):", parseInt(inputAmount.value));
  console.log("parseInt(props.goldBalance.value):", parseInt(props.goldBalance));

  if (toAmount.value === 0 || parseInt(inputAmount.value) > parseInt(props.goldBalance)) {
    return true;
  }

  return false;

});

//提交兑换请求
async function requestExchange() {
  try {
    common.showLoading("exchange token");
    await exchangeTokenFromGold(curToken.value, inputAmount.value);
    toastr['success'](langs.value.tipsSuccess);

    inputAmount.value = "";
  } catch (error) {
    toastr['error'](error.message);
  }
  common.hideLoading("exchange token");
}

//获取转账订单信息
async function loadOrders() {
  try {
    orders.value = await getExchangeTokenOrders();
  } catch (error) {
    toastr['error'](error.message);
  }
}

let timer = null;
onMounted(() => {
  loadOrders();
  timer = setInterval(loadOrders, 10000);
})

onUnmounted(() => {
  clearInterval(timer);
})


</script>
<style lang="scss" src="./SendToken.scss"></style>
<template>
  <div class="send_usdt">
    <div class="block item_balance">
      <!--金币usdt-->
      <div>
        <h2>{{ langs.goldBalance }}</h2>
        <div class="item_balance_content">
          <div class="token_bg">
            <icon_gold />
          </div>
          <div class="gold_count">{{ goldBalance }}</div>
        </div>
      </div>
    </div>

    <!--用户输入-->
    <div class="template u_chain block">
      <div class="main">
        <!--兑换数量-->
        <dl>
          <dt>{{ langs.amount }}</dt>
          <dd class="inputbox">
            <Iput2 :placeholder="langs.amountPlaceholder" width="100%" :backgroundcolor="'#1B203F'" :heigth="'.48rem'"
              v-model="inputAmount" :type="'number'" />
            <icon_gold class="icon" />
          </dd>
        </dl>

        <!--链币种类选择-->
        <dl>
          <dt>{{ langs.selectToken }}
            <span class="tips_title">（{{ langs.tipsUseTrx }}）</span>
          </dt>
          <dd :class="{ on: curToken === WALLET_TOKEN_TYPE.USDT }" @click="curToken = WALLET_TOKEN_TYPE.USDT">
            <span>
              <icon_usdt />&nbsp;USDT
            </span>
          </dd>
          <dd :class="{ on: curToken === WALLET_TOKEN_TYPE.TRX }" @click="curToken = WALLET_TOKEN_TYPE.TRX">
            <span>
              <icon_trx />&nbsp;TRX
            </span>
          </dd>
        </dl>

        <!--预计得到的数量-->
        <div class="estimated">
          <div class="num">
            <span class="title">{{ langs.getTokenAmout }}</span>
            <span class="amount">{{ toAmount }}
              <icon_trx v-if="curToken === WALLET_TOKEN_TYPE.TRX" class="icon" />
              <icon_usdt v-else class="icon" />
            </span>
          </div>

          <div class="num">
            <span class="title">{{ langs.chargeGold }}</span>
            <span class="amount">{{ chargeAmount }}
              <icon_gold class="icon" />
            </span>
          </div>

          <div class="num">
            <span class="title">{{ langs.totalGold }}</span>
            <span class="amount">{{ totalGoldAmount }}
              <icon_gold class="icon" />
            </span>
          </div>
        </div>

        <br>
        <div class="btnbox">
          <ui_btn_1 :width="'8rem'" :height="'0.48rem'" :disclick="disClick" @click="requestExchange">
            {{ langs.exchange }}
          </ui_btn_1>

          <!--提示信息-->
          <div class="hr"></div>
          <p class="tips"><span>*</span> {{ langs.tips7 }}</p>
          <template v-if="curToken === WALLET_TOKEN_TYPE.TRX">
            <p class="tips"><span>*</span> {{ langs.tips1 }}</p>
            <p class="tips"><span>*</span> {{ langs.tips2 }}</p>
            <p class="tips"><span>*</span> {{ langs.tips8 }}</p>
          </template>
          <template v-else>
            <p class="tips"><span>*</span> {{ langs.tips3 }}</p>
            <p class="tips"><span>*</span> {{ langs.tips4 }}</p>
          </template>

          <p class="tips">
            <span>*</span>
            {{ langs.tips6 }}
            &nbsp;
            <a target="_blank" :href="addressUrl">{{ langs.chainWeb }}</a>
          </p>
        </div>

      </div>
    </div>

    <!--历史记录-->
    <div class="block">
      <list_items :infos="orders"></list_items>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.gold_count {
  font-size: 0.38rem;
  color: #FFC738;
}

.template.u_chain {
  padding: 0.275rem 0.6rem;
  min-height: 100vh;
  min-height: auto;

  .main {
    .estimated {
      display: flex;
      flex-direction: column;
      gap: 0.3rem;

      .num {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .title {
        margin: 0;
        padding: 0;

        color: rgba($color: #fff, $alpha: 0.4);
        font-size: 0.14rem;
      }

      .amount {
        margin: 0;
        padding: 0;

        color: #fff;
        font-size: 0.14rem;

        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
  }
}

@media screen and (max-width: 1059px) {
  .template.u_chain {
    .main {
      width: 100%;
    }
  }
}
</style>