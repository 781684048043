import common from "./common";
import axios from "axios";
import web3 from "web3";

//定义货币精度
const __TOKEN_UNIT = "mwei";

//host
const __HOST = common.isProduction ? "https://pay.blockwar.io/" : "https://bk.bigfun4u.com";
let __instance = null;

//获取header
const getHeaders = () => {
  const headers = JSON.parse(localStorage.getItem("sendHeaders"));
  return {
    uid: headers["X-App-Uid"],
    platform: headers["X-Sys-Platform"],
    appid: headers["X-App-Id"],
    device_token: headers["X-Sys-Device-Token"],
    token: headers["X-App-Token"],
  };
};

//create axios
const getInstance = () => {
  if (!__instance) {
    //const headers = JSON.parse(localStorage.getItem("sendHeaders"));
    __instance = axios.create({
      baseURL: __HOST,
    });
  }
  return __instance;
};

//销毁接口
export const destoryInstance = () => {
  if (__instance) {
    __instance = null;
  }
};

//获取钱包地址
export const getWalletInfo = async () => {
  const headers = getHeaders();
  const response = await getInstance().post("/wallet/account/info", {
    tp: 4,
    ...headers,
  });

  if (response.data) {
    const { status, msg, data } = response.data;
    if (status === 0) {
      if (data.length > 0) {
        return data[0].chain_account;
      } else {
        return "";
      }
    } else {
      throw new Error(msg);
    }
  } else {
    throw new Error("No data");
  }
};

//创建钱包地址
export const createWallet = async () => {
  const headers = getHeaders();
  const response = await getInstance().post("/wallet/account", {
    tp: 4,
    ...headers,
  });

  if (response.data) {
    const { status, msg, data } = response.data;
    if (status !== 0) {
      throw new Error(msg);
    } else {
      return data.chain_account;
    }
  } else {
    throw new Error("No data");
  }
};

//定义货币类型
export const WALLET_TOKEN_TYPE = {
  //usdt
  USDT: 1,

  //TRX
  TRX: 3,
};

//查询余额
export const getWalletBalance = async (tokenType) => {
  const headers = getHeaders();
  const response = await getInstance().post("/wallet/account/balance", {
    tp: 4,
    chain_currency: tokenType,
    ...headers,
  });

  if (response.data) {
    const { status, msg, data } = response.data;
    if (status !== 0) {
      if (status === 13) {
        return {
          trxBalance: 0,
          usdtBalance: 0,
          totalBandwidth: 0,
          totalEnergy: 0,
          bandwidth: 0,
          energy: 0,
          activated: false,
        };
      } else {
        throw new Error(msg);
      }
    } else {
      const { trxAmount, usdtAmount, totalBandwidthUse, totalEnergyUse, totalBandwidth, totalEnergy } = data;
      return {
        trxBalance: web3.utils.fromWei(trxAmount, __TOKEN_UNIT),
        usdtBalance: web3.utils.fromWei(usdtAmount, __TOKEN_UNIT),
        totalBandwidth: totalBandwidth,
        totalEnergy: totalEnergy,
        bandwidth: totalBandwidthUse,
        energy: totalEnergyUse,
        activated: true,
      };
    }
  } else {
    throw new Error("No data");
  }
};

//转账
export const transferTokenTo = async (tokenType, to, amount) => {
  const headers = getHeaders();
  const response = await getInstance().post("/wallet/account/transfer", {
    tp: 4,
    chain_currency: tokenType,
    to_chain_account: to,
    amount: web3.utils.toWei(amount, __TOKEN_UNIT),
    ...headers,
  });

  if (response.data) {
    const { status, msg } = response.data;
    if (status !== 0) {
      throw new Error(msg);
    }
  } else {
    throw new Error("No data");
  }
};

//获取转账订单信息
export const getTransferTokenOrders = async (tokenType, to, amount) => {
  const headers = getHeaders();
  const response = await getInstance().post("/wallet/transfer-order/list", {
    ...headers,
  });

  if (response.data) {
    const { status, msg, data } = response.data;
    if (status !== 0) {
      throw new Error(msg);
    } else {
      return data;
    }
  } else {
    throw new Error("No data");
  }
};

//链币转金币
export const exchangeGoldFromToken = async (tokenType, amount) => {
  const headers = getHeaders();
  const response = await getInstance().post("/wallet/chain-currency/exchange", {
    tp: 4,
    chain_currency: tokenType,
    amount: web3.utils.toWei(amount, __TOKEN_UNIT),
    ...headers,
  });

  console.log("token to gold", response.data);

  if (response.data) {
    const { status, msg } = response.data;
    if (status !== 0) {
      throw new Error(msg);
    }
  } else {
    throw new Error("No data");
  }
};

//获取链币转金币订单
export const getExchangeGoldOrders = async () => {
  const headers = getHeaders();
  const response = await getInstance().post("/wallet/transfer-order/to-gold", {
    ...headers,
  });

  console.log("get token to gold orders", response.data);

  if (response.data) {
    const { status, msg, data } = response.data;
    if (status !== 0) {
      throw new Error(msg);
    } else {
      return data;
    }
  } else {
    throw new Error("No data");
  }
};

//金币转链币
export const exchangeTokenFromGold = async (tokenType, amount) => {
  const headers = getHeaders();
  const response = await getInstance().post("/wallet/gold/exchange", {
    tp: 4,
    chain_currency: tokenType,
    amount: amount,
    ...headers,
  });

  console.log("gold to token", response.data);

  if (response.data) {
    const { status, msg } = response.data;
    if (status !== 0) {
      throw new Error(msg);
    }
  } else {
    throw new Error("No data");
  }
};

//获取链币转金币订单
export const getExchangeTokenOrders = async () => {
  const headers = getHeaders();
  const response = await getInstance().post("/wallet/transfer-order/to-chain", {
    ...headers,
  });

  console.log("get gold to token ordors", response.data);

  if (response.data) {
    const { status, msg, data } = response.data;
    if (status !== 0) {
      throw new Error(msg);
    } else {
      return data;
    }
  } else {
    throw new Error("No data");
  }
};

//获取trx的价格
export const getTrxUsdtPrice = async () => {
  const headers = getHeaders();
  const response = await getInstance().post("/wallet/chain-currency/price", {
    ...headers,
  });

  console.log("getTrxUsdtPrice", response.data);

  if (response.data) {
    const { status, msg, data } = response.data;
    if (status !== 0) {
      throw new Error(msg);
    } else {
      return parseFloat(data.chain_currency_price);
    }
  } else {
    throw new Error("No data");
  }
};

//格式化地址
export const formatAddress = (address) => {
  if (address.length > 12) {
    return address.slice(0, 7) + "..." + address.slice(-5);
  } else {
    return address;
  }
};

//生成地址连接
export const createAddressLink = (address) => {
  if (common.isProduction) {
    return `https://tronscan.org/#/address/${address}`;
  } else {
    return `https://shasta.tronscan.org/#/address/${address}`;
  }
};

export const createHashLink = (address) => {
  if (common.isProduction) {
    return `https://tronscan.org/#/transaction/${address}`;
  } else {
    return `https://shasta.tronscan.org/#/transaction/${address}`;
  }
};

export const fromWei = (amount) => {
  return web3.utils.fromWei(amount, __TOKEN_UNIT);
};
