<template>
  <span class="icon_free">
    <svg
      width="96"
      height="30"
      viewBox="0 0 96 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="96" height="30" rx="15" fill="url(#paint0_linear_2039_23286)" />
      <path
        d="M11.2266 15H9.375V13.125H13.1016L13.5703 12.6562L9.75 8.85937L11.0859 7.52344L14.9062 11.3438L22.2188 4.03125C20.1563 2.67188 17.6953 1.875 15.0234 1.875C7.78125 1.875 1.89844 7.75781 1.89844 15C1.89844 17.6484 2.69531 20.1094 4.05469 22.1953L11.2266 15ZM3.375 25.5469L4.71094 26.8828L6.5625 25.0313C8.83594 26.9531 11.8125 28.125 15.0234 28.125C22.2656 28.125 28.1484 22.2422 28.1484 15C28.1484 11.7656 26.9766 8.8125 25.0313 6.51562L26.7422 4.80469L25.4063 3.46875M20.625 18.75H15.9844V24.4219H14.1094V18.75H12.8203L15.9844 15.5859V16.875H20.625V18.75ZM20.625 15H16.5703L18.4453 13.125H20.625V15Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2039_23286"
          x1="-1"
          y1="16.5"
          x2="96"
          y2="15"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#0400AB" />
          <stop offset="1" stop-color="#DB0D14" />
        </linearGradient>
      </defs>
    </svg>
    <span><slot>限時免費</slot></span>
  </span>
</template>
<style lang="scss" scoped>
.icon_free {
  display: inline-block;
  position: relative;
  width: 0.96rem;
  height: 0.3rem;
  svg {
    position: absolute;
    top: 0;
    left: 0;
  }
  span {
    width: 0.7rem;
    height: 0.3rem;
    line-height: 0.3rem;
    position: absolute;
    top: 0;
    right: 0;
    text-align: center;
    font-size: 0.12rem;
    color: #fff;
  }
}
</style>
<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
let store = useStore();
let lang = computed(() => {
  return store.state.lang;
});
</script>
