<template>
  <div class="template list_table">
    <div class="t">{{ lang == "en" ? "Transaction List" : "交易歷史記錄" }}</div>
    <dl class="onlypc" v-if="props.modelValue == 1 || props.modelValue == 2">
      <dt>
        <span>提交時間</span><span>類型</span><span>金額</span><span>手續費</span><span>狀態</span><span>編號</span>
      </dt>
      <dd v-if="dataList.length > 0" v-for="(item, i) in dataList" :key="i">
        <span>{{ common.formatDateTime(item.add_time) }}</span>
        <span v-if="item.op_value > 0">{{ lang == "en" ? "Buy" : "兌入" }}</span>
        <span v-else>{{ lang == "en" ? "Sell" : "兌出" }}</span>
        <span><i v-html="formatAssetInfos(item.asset_type, item.op_value)"></i></span>
        <span>{{ item.asset_type == 1 ? item.pay_charge / 1000 : item.pay_charge }}</span>
        <span class="green">{{ lang == "en" ? "Completed" : "完成" }}</span>
        <span>{{ item.seqnum }}</span>
      </dd>
      <dd v-else class="null">{{ lang == "en" ? "Nothing Yet" : "暫無數據" }}</dd>
    </dl>
    <dl class="onlypc" v-else-if="props.modelValue == 3 || props.modelValue == 4">
      <dt><span>提交時間</span><span>金額</span><span>手續費</span><span>狀態</span></dt>
      <dd v-if="dataList.length > 0" v-for="(item, i) in dataList" :key="i">
        <span>{{ common.formatDateTime(item.add_time) }}</span>
        <span>{{ item.short_count }}
          <Icon_u class="icon" />
        </span>
        <span>{{ item.charge / 1000 }}</span>
        <span :class="{
          status: true,
          orange: item.status == 0,
          green: item.status == 1,
          red: item.status == 2,
        }">{{ formatChainStatus(item.status) }}</span>
      </dd>
      <dd class="null" v-else>{{ lang == "en" ? "Nothing Yet" : "暫無數據" }}</dd>
    </dl>
  </div>
</template>
<script setup>
import axios from "axios";
import { computed, onMounted, ref, inject } from "vue";
import { useStore } from "vuex";
import common from "../../utils/common";
import Icon_u from "../ui/icon/icon_u.vue";
let formatChainStatus = inject("formatChainStatus");
let props = defineProps(["modelValue"]);
let store = useStore();
let userInfo = computed(() => {
  return store.state.userInfo;
});
let lang = computed(() => {
  return store.state.lang;
});

const formatAssetInfos = (type, val) => {
  if (type == 1) {
    (type = "u"), (val = val / 1000);
  }
  const a = [{ type: type, value: Math.abs(val) }];
  return common.formatAssetInfos(a);
};

let dataList = ref([]);
onMounted(() => {
  let getUrl;
  //   兌入兑出金幣
  if (props.modelValue == 1 || props.modelValue == 2) {
    getUrl =
      common.userApi.requestUrl +
      "/finance_api/transaction_records?offset=0&count=50&ops=12";
    axios.get(getUrl).then((r) => {
      dataList.value = r.data.records;
    });
  } else if (props.modelValue == 3) {
    getUrl = common.userApi.requestUrl + "/finance_api/bwallet/excharge_orders?";
    axios.get(getUrl).then((r) => {
      dataList.value = r.data.infos;
    });
  } else if (props.modelValue == 4) {
    getUrl = common.userApi.requestUrl + "/finance_api/bwallet/withdraw_orders?";
    axios.get(getUrl).then((r) => {
      dataList.value = r.data.infos;
    });
  }
});
</script>
<style lang="scss" scoped>
.template.list_table {
  width: 100%;
  dd {
    span {
      white-space: nowrap;
      word-break: keep-all;
      word-wrap: normal;

      i {
        font-style: normal;
      }

      svg {
        width: 0.14rem;
        height: 0.14rem;
        margin-left: 0.05rem;
      }
    }
  }
}
</style>
