<template>
  <div class="template list_table">
    <div class="t">{{ "交易歷史記錄" }}</div>
    <dl class="onlypc">
      <slot></slot>
    </dl>
  </div>
</template>

<script setup>
</script>

<style lang="scss" scoped>
.template.list_table {
  width: 100%;

  dd {
    span {
      white-space: nowrap;
      word-break: keep-all;
      word-wrap: normal;

      i {
        font-style: normal;
      }

      svg {
        width: 0.14rem;
        height: 0.14rem;
        margin-left: 0.05rem;
      }
    }
  }
}
</style>
