<template>
  <div class="template u_chain">
    <!-- 充值 -->
    <div class="main" v-if="props.modelValue == 3 && !detailItem">
        <dl>
            <dt>{{ lang=='en'?'Coin Type':'虛擬幣種類' }}</dt>
            <dd :class="{on:chain_currency==2}" @click="(chain_currency=2,chain_tp=2,chain_payment_tp=1)"><span><icon_u />WU</span></dd>
            <dd :class="{on:chain_currency==1}" @click="chain_currency=1"><span><icon_usdt />USDT</span></dd>
        </dl>
        <dl>
            <dt>{{ lang=='en'?'Network':'充值渠道' }}</dt>
            <dd v-if="chain_currency==1" :class="{on:chain_tp==4&&chain_payment_tp==2}" @click="(chain_tp=4,chain_payment_tp=2)">TRON-TRC20</dd>
            <dd :class="{on:chain_tp==2&&chain_payment_tp==1}" @click="(chain_tp=2,chain_payment_tp=1)">BSC-BEP20</dd>
        </dl>
        <div class="dl">
            <span class="label">{{ lang == "en" ? "Address" : "系統錢包地址" }}</span>
            <span class="val">{{ systemAccount }}<svg @click="copyLinks" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M12 12V13.5V15H10.5H4.5H3V13.5V7.5V6H4.5H6V7.5H4.5V13.5H10.5V12H12Z" fill="white" fill-opacity="0.5"/>
              <rect x="6.75" y="3.75" width="7.5" height="7.5" stroke="white" stroke-opacity="0.5" stroke-width="1.5"/>
            </svg></span>
        </div>
        <div class="dl">
            <span class="label">{{ lang == "en" ? "Amount" : "充值數量" }}</span>
            <div class="input">
                <Iput2
                :placeholder="lang == 'en' ? 'Please Enter Amount' : '請輸入充值數量'"
                :width="'100%'"
                :backgroundcolor="'#1B203F'"
                :heigth="'.64rem'"
                v-model="numVal"
                :type="'number'"
                          />&ensp;
                          <icon_u class="icon" />
            </div>
        </div>
        <div class="btnbox">
            <ui_btn_1
              v-if="numVal > 0"
              class="btn"
              @click="showExchangeTag = true"
              :width="'100%'"
              :height="'0.48rem'"
              >{{ lang == "en" ? "Confirm Deposit" : "確認充值" }}</ui_btn_1
            >
            <ui_btn_1
              v-else
              class="btn"
              :disclick="true"
              :width="'100%'"
              :height="'0.48rem'"
              >{{ lang == "en" ? "Confirm Deposit" : "確認充值" }}</ui_btn_1
            >
          </div>
          <p class="tips">
            <span>*</span
            >{{
              lang == "en"
                ? "Reminder: Please make a transfer to the listed address within 30 minutes.Attempts after that time period will not be accepted.Transfer amount must match designated amount or despoit cannot be confirmed."
                : "提示：請在30分鐘內轉賬到指定錢包地址，超過時間後請勿再轉賬，否則無法到賬。指定金額必須一致，否者無法確認金額。"
            }}
          </p>
          <p class="tips">
            <span>*</span>
            {{
              lang == "en"
                ? "Please note that only decentralised wallets are supported for trading operations, using exchange wallets (Coin, Fire, Ouyi, pexpay) may lead to unforeseen consequences <we recommend using decentralised wallets such as metamask, TokenPocket, imToken, etc. for operations>."
                : "請注意只支持使用去中心化錢包進行交易操作 ，如使用交易所錢包（幣安、火幣、歐易、pexpay），將可能導致不可預期的後果<建議使用metamask、TokenPocket、imToken等去中心化錢包進行操作>"
            }}
          </p>
          <Confirm
        v-model="showExchangeTag"
        :title="lang == 'en' ? 'Confirm Transaction' : '訂單確認'"
        :confirmtxt="lang == 'en' ? 'Submit' : '提交'"
        :canceltxt="lang == 'en' ? 'Cancel' : '取消'"
        @confirm="exchange"
      >
        <ul class="confirm">
          <li>
            <span class="label">{{ lang == "en" ? "Network" : "充值渠道" }}</span
            ><span class="val">{{ channelShowName }}</span>
          </li>
          <li>
            <span class="label">{{ lang == "en" ? "Address" : "系統錢包地址" }}</span
            ><span class="val red">{{ systemAccount }}</span>
          </li>
          <li>
            <span class="label">{{ lang == "en" ? "Amount" : "充值金額" }}</span
            ><span class="val red">{{ numVal * 1 }}</span
            >&ensp;
            <icon_u class="icon" />
          </li>
        </ul>
      </Confirm>
    </div>
    <!-- 提現 -->
    <div class="main" v-if="props.modelValue == 4 && !detailItem">
      <div class="balance" v-if="balance">
        <div>
          <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="12" cy="12.6688" r="12" fill="#7ECC42"/>
          <path d="M10.6662 9.55768H13.3329V17.5577H15.1107V19.3355H9.77734V17.5577H11.5551V11.3355H10.6662V9.55768Z" fill="#7ECC42"/>
          <ellipse cx="11.9993" cy="7.33541" rx="1.33333" ry="1.33333" fill="#7ECC42"/>
          <path d="M8.00043 10.4465L6.22266 12.2243L11.1115 17.1132L18.6671 9.55762L16.8893 7.77985L11.1115 13.5576L8.00043 10.4465Z" fill="white"/>
          </svg>
          <span>{{ lang == "en" ? "Network Balance" : "渠道餘額" }}</span> {{
              chain_tp == 2
                ? common.formatCount18Float(balance)
                : common.formatCount6Float(balance)
            }}<icon_u class="icon" />
        </div>
          <svg @click="balance=null" class="close" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.11133 9.15494L8.65519 7.61108L12.0002 10.9561L15.3452 7.61108L16.8891 9.15494L13.5441 12.5L16.8891 15.845L15.3452 17.3889L12.0002 14.0438L8.65519 17.3889L7.11133 15.845L10.4564 12.5L7.11133 9.15494Z" fill="white"/>
            </svg>
            
      </div>
        <dl>
            <dt>{{ lang=='en'?'Coin Type':'虛擬幣種類' }}</dt>
            <dd :class="{on:chain_currency==2}" @click="(chain_currency=2,chain_tp=2,chain_payment_tp=1)">WU</dd>
            <dd :class="{on:chain_currency==1}" @click="chain_currency=1">USDT</dd>
        </dl>
        <dl>
            <dt>{{ lang=='en'?'Network':'提現渠道' }}</dt>
            <dd v-if="chain_currency==1" :class="{on:chain_tp==4&&chain_payment_tp==2}" @click="(chain_tp=4,chain_payment_tp=2)">TRON-TRC20</dd>
            <dd :class="{on:chain_tp==2&&chain_payment_tp==1}" @click="(chain_tp=2,chain_payment_tp=1)">BSC-BEP20</dd>
            <dd class="btn"
              @click="getChannelBalance"
              v-if="!balance"
              >{{ lang == "en" ? "Check Network Balance" : "查詢渠道餘額" }}</dd>
        </dl>
        <dl>
          <dt>{{ lang == "en" ? "Address" : "錢包地址" }}</dt>
          <dd class="inputbox"><Iput2
            :placeholder="lang == 'en' ? 'Address' : '請輸入錢包地址'"
            width="100%"
            :backgroundcolor="'#1B203F'"
            :heigth="'.48rem'"
            v-model="userWalletAddress"
            type="text"
          /></dd>
        </dl>
        <dl>
          <dt>{{ lang == "en" ? "Withdraw Amount" : "提現數量" }}</dt>
          <dd class="inputbox">
            <Iput2
            :placeholder="lang == 'en' ? 'Please Enter Amount' : '請輸入提現數量'"
            width="100%"
            :backgroundcolor="'#1B203F'"
            :heigth="'.48rem'"
            v-model="numVal"
            :type="'number'"
          /><icon_u class="icon" />
          </dd>
        </dl>
        
        <div class="minimum">{{ lang == "en" ? "Minimum Amount: " : "最低提現額："
            }}{{ bsc_withdraw_lower_bound.value / R }}<icon_u class="icon" />
        </div>
        <div class="estimated">
          <span>{{ lang == "en" ? "Estimated Fee" : "預計手續費" }}</span>
            <span>{{ withdrawExchangeCharge }}<icon_u class="icon" /></span>
        </div>
        <div class="btnbox">
          <ui_btn_1
            v-if="
              numVal > 0 &&
              userWalletAddress &&
              numVal >= bsc_withdraw_lower_bound.value / R
            "
            class="btn"
            @click="showWithdrawTag = true"
            :width="'100%'"
            :height="'0.48rem'"
            >{{ lang == "en" ? "Confirm Withdrawl" : "確認提現" }}
          </ui_btn_1>
          <ui_btn_1
            v-else
            class="btn"
            :disclick="true"
            :width="'100%'"
            :height="'0.48rem'"
            >{{ lang == "en" ? "Confirm Withdrawl" : "確認提現" }}</ui_btn_1
          >
        </div>
        <div class="hr"></div>
        <p class="tips">
          <span>*</span>
          {{
            lang == "en"
              ? "Reminder: Withdraw may take longer than expected due to network delays."
              : "提示：提現會存在延時到賬情況，需根據鏈路情況決定。"
          }}
        </p>
        <p class="tips">
          <span>*</span>
          {{
            lang == "en"
              ? "Please note that only decentralised wallets are supported for trading operations, using exchange wallets (Coin, Fire, Ouyi, pexpay) may lead to unforeseen consequences <we recommend using decentralised wallets such as metamask, TokenPocket, imToken, etc. for operations>."
              : "請注意只支持使用去中心化錢包進行交易操作 ，如使用交易所錢包（幣安、火幣、歐易、pexpay），將可能導致不可預期的後果<建議使用metamask、TokenPocket、imToken等去中心化錢包進行操作>"
          }}
        </p>
        <Confirm
          v-model="showWithdrawTag"
          :title="lang == 'en' ? 'Confirm Transaction' : '訂單確認'"
          :confirmtxt="lang == 'en' ? 'Submit' : '提交'"
          :canceltxt="lang == 'en' ? 'Cancel' : '取消'"
          @confirm="withdraw"
        >
          <ul class="confirm">
            <li>
              <span class="label">{{ lang == "en" ? "Network" : "提現渠道" }}</span
              ><span class="val">{{ channelShowName }}</span>
            </li>
            <li>
              <span class="label">{{ lang == "en" ? "Address" : "錢包地址" }}</span
              ><span class="val red">{{ userWalletAddress }}</span>
            </li>
            <li>
              <span class="label">{{ lang == "en" ? "Withdraw Amount" : "提現金額" }}</span
              ><span class="val red">{{ numVal * 1 }}</span>
              &ensp;
              <icon_u class="icon" />
            </li>
            <li>
              <span class="label">{{ lang == "en" ? "Estimated Fee" : "預計手續費" }}</span
              ><span class="val">{{ withdrawExchangeCharge }}</span>
              &ensp;
              <icon_u class="icon" />
            </li>
          </ul>
        </Confirm>
    </div>
  </div>
</template>
<script setup>
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import common from "../../utils/common";
import axios from "axios";
import Iput2 from "../ui/ui_input_2.vue";
import ui_btn_1 from "../ui/ui_btn_1.vue";
import ui_btn_2 from "../ui/ui_btn_2.vue";
import Select from "../ui/ui_select.vue";
import Confirm from "../ui/ui_confirm_2.vue";
import icon_u from "@/components/ui/icon/icon_u";
import icon_usdt from '@/components/ui/icon/icon_usdt'
// import Detail from "./Detail.vue";
// import Detail_deposit from "./Detail_deposit.vue";
import { useRouter } from "vue-router";

let detailItem = ref(null);

let store = useStore();
let router = useRouter();
let lang = computed(() => {
  return store.state.lang;
});
let props = defineProps(["modelValue"]);
let emites = defineEmits(["update:modelValue"]);

const R = 1000; //U的顯示要除1000
let Charge = 0; //返回的未處理的手續費率
// 充值或兌換的金額
let numVal = ref();
watch(numVal, (newV, oldV) => {
  if (newV) {
    withdrawExchangeCharge.value = newV * Charge;
  }
});
// 系統錢包地址，充值時用
let systemAccount = ref();
// 用戶錢包地址，提現時用
let userWalletAddress = ref("");
// 區塊鏈類型 2 BSC， 3 ETH
let chain_tp = ref();
//  鏈幣支付類型 1 ERC20
let chain_payment_tp = ref();
let chain_payment_name = ref();
// 幣種，1為USDT， 2為WAT
let chain_currency = ref();
// 區塊鏈提現金額下限
let bsc_withdraw_lower_bound = computed(() => {
  let c = store.state.sysConfig;
  for (let i = 0; i < c.length; i++) {
    if (c[i].name == "bsc_withdraw_lower_bound") {
      return c[i];
    }
  }
  return null;
});
// 充值和提現的手續費列表
let exchangeWithDrawChargeList = computed(() => {
  if (store.state.sysConfig) {
    let c = store.state.sysConfig;
    for (let i = 0; i < c.length; i++) {
      if (c[i].name == "bwallet_charge_rate_info") {
        let objVal = JSON.parse(c[i].value);
        return objVal;
      }
    }
    return null;
  }
});

// U幣提現確認框
let showWithdrawTag = ref(false);
// U幣提現手續費
let withdrawExchangeCharge = ref("");
const getWithdrawExchangeCharge = () => {
  console.log(
    "systemAccount.value:",
    systemAccount.value,
    "; chain_currency.value:",
    chain_currency.value,
    "; chain_tp.value:",
    chain_tp.value,
    "; chain_payment_tp.value:",
    chain_payment_tp.value,
    "channelShowName",
    channelShowName.value
  );
  console.log("exchangeWithDrawChargeList", exchangeWithDrawChargeList.value);
  if (chain_currency.value == 2 && chain_tp.value == 2 && chain_payment_tp.value == 1) {
    Charge = exchangeWithDrawChargeList.value["bsc_wat_withdraw_rate"] / 10000;
  } else if (
    chain_currency.value == 1 &&
    chain_tp.value == 2 &&
    chain_payment_tp.value == 1
  ) {
    Charge = exchangeWithDrawChargeList.value["bsc_usdt_withdraw_rate"] / 10000;
  } else if (
    chain_currency.value == 1 &&
    chain_tp.value == 4 &&
    chain_payment_tp.value == 2
  ) {
    Charge = exchangeWithDrawChargeList.value["tron_usdt_withdraw_rate"] / 10000;
  }
  if (numVal.value) {
    withdrawExchangeCharge.value = numVal.value * Charge;
  } else {
    withdrawExchangeCharge.value = "0.00";
  }
  console.log("withdrawExchangeCharge.value", withdrawExchangeCharge.value);
};
// 渠道列表
let channel_infos = ref();
if (props.modelValue == 3 || props.modelValue == 4) {
  common.showLoading("u channel_infos");
  const sendUrl = common.userApi.requestUrl + "/finance_api/bwallet/channel_infos";
  const sendHeader = common.buildHeaders("form");
  axios.get(sendUrl, { headers: sendHeader }).then((r) => {
    common.hideLoading("u channel_infos");
    if (r.data.code == 0) {
      console.log(r.data.infos);
      let a = [];
      for (let i = 0; i < r.data.infos.length; i++) {
        let d = r.data.infos[i].chain_currency_infos;
        for (let II = 0; II < d.length; II++) {
          a.push({
            name:
              common.chain_tp[r.data.infos[i].chain_tp] +
              "-" +
              (r.data.infos[i].chain_payment_name + "-") +
              (d[II].currency == 1 ? "USDT" : d[II].currency == 2 ? "WU" : "Other"),
            chain_payment_tp: r.data.infos[i].chain_payment_tp,
            chain_payment_name: r.data.infos[i].chain_payment_name,
            chain_tp: r.data.infos[i].chain_tp,
            systemAccount: d[II].system_account,
            tokens: d[II].system_account_tokens,
            // 默認為WAT
            default: d[II].currency == 2 ? true : false,
            currency: d[II].currency,
          });
          // 默認充值地址
          if (d[II].currency == 2) {
            // 默認系統錢包地址
            systemAccount.value = d[II].system_account;
            // 默認幣種
            chain_currency.value = d[II].currency;
            // 默認區塊鏈類型
            chain_tp.value = r.data.infos[i].chain_tp;
            // 默認區塊鏈支付類型
            chain_payment_tp.value = r.data.infos[i].chain_payment_tp;
            chain_payment_name.value = r.data.infos[i].chain_payment_name;
          }
        }
      }
      for (let I = 0; I < a.length; I++) {
        a[I].val = I;
      }
      channel_infos.value = a;
      getWithdrawExchangeCharge();
      console.log("channel_infos", channel_infos.value);
      console.log(
        "systemAccount.value:",
        systemAccount.value,
        "; chain_currency.value:",
        chain_currency.value,
        "; chain_tp.value:",
        chain_tp.value,
        "; chain_payment_tp.value:",
        chain_payment_tp.value
      );
    }
  });
}
//選擇了渠道，修改相應的賬號和幣種和手續費率
const changeChannel = (v) => {
  console.log(v, channel_infos.value);
  chain_tp.value = channel_infos.value[v].chain_tp;
  chain_payment_tp.value = channel_infos.value[v].chain_payment_tp;
  chain_payment_name.value = channel_infos.value[v].chain_payment_name;
  chain_currency.value = channel_infos.value[v].currency;
  systemAccount.value = channel_infos.value[v].systemAccount;
  balance.value = null;
  getWithdrawExchangeCharge();
};

//渠道顯示名
let channelShowName = computed(() => {
  return (
    common.chain_tp[chain_tp.value] +
    "-" +
    chain_payment_name.value +
    "-" +
    (chain_currency.value == 1 ? "USDT" : chain_currency.value == 2 ? "WU" : "Other")
  );
});

//U幣充值
let showExchangeTag = ref(false);
const exchange = () => {
  if (numVal.value && numVal.value > 0) {
    const sendUrl = common.userApi.requestUrl + "/finance_api/bwallet/excharge_orders";
    const sendHeader = common.buildHeaders("form");
    const sendData = common.buildSendData({
      chain_tp: chain_tp.value,
      chain_payment_tp: chain_payment_tp.value,
      chain_payment_name: chain_payment_name.value,
      chain_currency: chain_currency.value,
      asset_count: numVal.value * R,
    });
    common.showLoading("u chain exchange");
    axios.put(sendUrl, sendData, { headers: sendHeader }).then((r) => {
      console.log(r);
      if (r.data.code == 0) {
        toastr["success"](r.data.msg);
        router.push("/u/detail?type=excharge&first=true&data=item");
      } else {
        toastr["error"](r.data.msg);
      }
      common.hideLoading("u chain exchange");
    });
  }
};
// U幣提現
const withdraw = () => {
  console.log("withdraw");
  const sendUrl = common.userApi.requestUrl + "/finance_api/bwallet/withdraw_orders";
  const sendData = common.buildSendData({
    chain_tp: chain_tp.value,
    chain_payment_tp: chain_payment_tp.value,
    chain_currency: chain_currency.value,
    asset_count: numVal.value * R,
    chain_account: userWalletAddress.value,
  });
  common.showLoading("u chain withdraw", sendData);
  console.log("u chain withdraw", sendData);
  axios.put(sendUrl, sendData, { headers: common.buildHeaders("form") }).then((r) => {
    console.log(r);
    if (r.data.code == 0) {
      toastr["success"](r.data.msg);
      // detailItem.value = {
      //   charge: r.data.charge,
      //   short_count: r.data.short_count,
      //   chain_tp: chain_tp.value,
      //   chain_payment_tp: chain_payment_tp.value,
      //   chain_payment_name: chain_payment_name.value,
      //   chain_currency: chain_currency.value,
      //   asset_count: numVal.value * R,
      //   chain_account: userWalletAddress.value,
      //   add_time: new Date().getTime(),
      //   hideBackArrow: true, //自加屬性，隱藏子組的返回，用本組件的返回
      //   type: 4, //自己加的屬性，表示是提現
      //   status: 3, //自己加的屬性，表示轉賬中
      // };
      // localStorage.setItem("uDetailItem", JSON.stringify(detailItem.value));
      router.push("/u/detail?type=withdraw&first=true&data=item");
    } else {
      toastr["error"](r.data.msg);
    }
    common.hideLoading("u chain withdraw");
  });
};

// 查詢渠道鏈幣餘額
let balance = ref();
const getChannelBalance = () => {
  const sendUrl =
    common.userApi.requestUrl +
    `/finance_api/bwallet/balance?chain_tp=${chain_tp.value}&currency=${chain_currency.value}&account=${systemAccount.value}`;
  common.showLoading("getChannelBalance");
  axios
    .get(sendUrl, { headers: common.buildHeaders("form") })
    .then((r) => {
      console.log(r);
      balance.value = r.data.amount;
    })
    .finally(() => {
      common.hideLoading("getChannelBalance");
    });
};

watch([chain_tp,chain_payment_tp,chain_currency],()=>{
    console.log('chain_tp,chain_payment_tp,chain_currency',chain_tp.value,chain_payment_tp.value,chain_currency.value);
  systemAccount.value = channel_infos.value[chain_payment_tp.value].systemAccount;
    console.log('systemAccount.value',systemAccount.value);
    balance.value = '';
})

const copyLinks = async ()=>{
  await common.toClipboard(systemAccount.value);
  toastr["success"]("Copied successfully !!");
}
</script>
<style lang="scss" scoped>
.template.u_chain{
  padding: 0.275rem 0.6rem;
  min-height: 100vh;
  min-height: auto;
  .main{
    .estimated{      
      display: flex;
      justify-content: space-between;
    }
  }
  .balance{
    border-radius: 5px;
    background: rgba(149, 255, 67, 0.15);
    padding: .13rem;
    display: flex;
    margin-bottom: .16rem;
    justify-content: space-between;
    align-items: center;
    div{
      display: flex;
      align-items: center;
    }
    span{
      color: #7ECC42;
      margin-right: .1rem;
    }
    svg{
      width: .18rem;
      height: auto;
      margin: 0 .1rem;
      &:first-child{
        width: .18rem;
      }
    }
  }
}
.content {
  .icon {
    width: 0.26rem;
    margin-right: 0.1rem;
  }

  .label {
    margin-right: 0.26rem;
    display: inline-block;
    width: 1rem;
    text-align: right;
  }
  .num {
    display: flex;
    align-items: center;
    margin-bottom: 0.3rem;
    i {
      display: flex;
      align-items: center;
      font-style: normal;
    }
  }

  .form {
    font-size: 0.16rem;
    padding: 0.6rem;

    li {
      display: flex;
      align-items: center;
      height: 0.6rem;
      margin-bottom: 0.2rem;

      .balance {
        margin-left: 0.2rem;
        display: flex;
        align-items: center;
      }
      .flex {
        display: flex;
        align-items: center;
      }
    }
  }

  .btnbox {
    padding: 0 0.6rem;
  }
}

.confirm {
  li {
    min-height: 0.6rem;
    display: flex;
    align-items: center;

    .label {
      width: 1rem;
      color: #828282;
      text-align: right;
      font-size: 0.16rem;
      line-height: 22px;
      text-align: right;
      text-transform: capitalize;
      margin-right: 0.22rem;
      flex-shrink: 0;
      flex-grow: 0;
    }

    .val {
      color: #333;
    }
    svg {
      width: 0.24rem;
      height: 0.24rem;
    }
  }
}
@media screen and (max-width: 1059px){
  .template.u_chain{
    .main{
      width: 100%;
    }
  }
}
</style>
