<template>
  <div class="template u_exchange">
    <div class="exchangebox" id="exchangebox">
      <div class="caption">
        <div class="block_layout">
          <span class="back" @click="$emit('update:modelValue', null)">
            <IconBack />
            <span class="onlypc">{{ lang == "en" ? "Go Back" : "返回" }}</span>
            <div
              class="onlymobile mortina"
              v-if="props.modelValue == 1 || props.modelValue == 2"
            >
              <span v-if="lang != 'en'"
                >{{ props.modelValue == 1 ? "兌入" : "兌出" }}金幣</span
              >
              <span v-else>{{ props.modelValue == 1 ? "Buy " : "Sell " }}Gold</span>
            </div>
            <div class="onlymobile mortina" v-else>
              <span>{{
                props.modelValue == 3
                  ? lang == "en"
                    ? "Deposit"
                    : "充值"
                  : lang == "en"
                  ? "Withdraw"
                  : "提現"
              }}</span>
            </div>
          </span>
          <div
            class="detail_title onlypc"
            v-if="props.modelValue == 1 || props.modelValue == 2"
          >
            <span v-if="lang != 'en'"
              >{{ props.modelValue == 1 ? "兌入" : "兌出" }}金幣</span
            >
            <span v-else>{{ props.modelValue == 1 ? "Buy " : "Sell " }}Gold</span>
          </div>
          <div class="detail_title onlypc" v-else>
            {{
              props.modelValue == 3
                ? lang == "en"
                  ? "Deposit"
                  : "充值"
                : lang == "en"
                ? "Withdraw"
                : "提現"
            }}
          </div>
        </div>
      </div>
      <div class="block_layout contentbox">
        <div class="finance block">
          <template
            v-if="props.modelValue == 1 || props.modelValue == 3 || props.modelValue == 4"
          >
            <h2>{{ lang == "en" ? "U Amount" : "U幣餘額" }}</h2>
            <div class="flex">
              <div class="ico">
                <icon_u />
              </div>
              <div class="data">
                <div class="u">{{ props.u }}</div>
              </div>
            </div>
          </template>
          <template v-if="props.modelValue == 2">
            <h2>{{ lang == "en" ? "Gold Amount" : "金幣數量" }}</h2>
            <div class="flex">
              <div class="ico">
                <icon_gold />
              </div>
              <div class="data">
                <div class="gold">{{ props.gold }}</div>
              </div>
            </div>
          </template>
        </div>
        <div class="block" v-if="props.modelValue == 1 || props.modelValue == 2">
          <div class="content main">
            <template v-if="props.modelValue == 1">
              <div class="dl">
                <span class="label">{{ lang == "en" ? "Buy Amount" : "兌入數量" }}</span>
                <div class="input">
                  <input
                    type="number"
                    :placeholder="
                      lang == 'en' ? 'Please Enter Gold Amount To Buy' : '請輸入兌入數量'
                    "
                    v-model.number.trim="numVal"
                    @keyup="limitInputNum"
                    onpaste="return false"
                  />&ensp;
                  <icon_gold class="icon" />
                </div>
              </div>
              <div class="estimated">
                <div class="num" v-if="chargeDeduction == 0 || chargeDeduction > 0">
                  <span class="label">{{
                    lang == "en" ? "Free Transactions" : "免手續費額度"
                  }}</span>
                  <i
                    ><em>{{ lang == "en" ? "Remaining" : "剩餘" }}</em>
                    {{ chargeDeduction / R }}&ensp;
                    <icon_u class="icon" />
                  </i>
                </div>
                <div class="num">
                  <span class="label">{{ lang == "en" ? "Fee" : "手續費" }}</span>
                  <i>
                    {{ asset_exchange_charge_rate_for_diamond_to / R }}&ensp;
                    <icon_u class="icon" />
                  </i>
                </div>
                <div class="num">
                  <span class="label">{{
                    lang == "en" ? "Estimated Arrival" : "預計到賬"
                  }}</span>
                  <i>
                    <span>{{ numVal || 0 }}&ensp;</span>
                    <icon_gold class="icon" />
                  </i>
                </div>
                <div class="num">
                  <span class="label">{{
                    lang == "en" ? "Estimated Cost" : "預計花費"
                  }}</span>
                  <i>
                    <!-- <span v-if="(numVal && numVal>0)">{{ (numVal / (1 - asset_exchange_charge_rate_for_diamond_to / numVal) / R).toFixed(4)}}&ensp;</span> -->
                    <span v-if="numVal && numVal > 0"
                      >{{
                        (
                          numVal / R +
                          asset_exchange_charge_rate_for_diamond_to / R
                        ).toFixed(4)
                      }}&ensp;</span
                    >
                    <span v-else>0&ensp;</span>
                    <icon_u class="icon" />
                  </i>
                </div>
              </div>
              <!-- <ui_btn_1 v-if="(numVal && (numVal / (1 - asset_exchange_charge_rate_for_diamond_to / numVal) / R).toFixed(4)<= numVal)" class="btn" @click="exchange" :width="'1.67rem'" :height="'0.48rem'">{{ lang == 'en' ?'Confirm Buy':'確認兌入'}}</ui_btn_1> -->
              <ui_btn_1
                v-if="
                  numVal &&
                  (numVal / R + asset_exchange_charge_rate_for_diamond_to / R).toFixed(
                    4
                  ) <= props.u &&
                  numVal > 0
                "
                class="btn"
                @click="exchange"
                :width="'100%'"
                :height="'0.48rem'"
                >{{ lang == "en" ? "Confirm Buy" : "確認兌入" }}</ui_btn_1
              >
              <ui_btn_1
                v-else
                class="btn"
                :disclick="true"
                :width="'100%'"
                :height="'0.48rem'"
                >{{ lang == "en" ? "Confirm Buy" : "確認兌入" }}</ui_btn_1
              >
            </template>
            <template v-else-if="props.modelValue == 2">
              <div class="dl">
                <span class="label">{{ lang == "en" ? "Sell Amount" : "兌出數量" }}</span>
                <div class="input">
                  <input
                    :placeholder="
                      lang == 'en' ? 'Please Enter Gold Amount To Sell' : '請輸入兌出數量'
                    "
                    v-model.number.trim="numVal"
                    @keyup="limitInputNum"
                    onpaste="return false"
                    type="number"
                  />&ensp;
                  <icon_gold class="icon" />
                </div>
              </div>
              <div class="estimated">
                <div class="num" v-if="chargeDeduction == 0 || chargeDeduction > 0">
                  <span class="label">{{
                    lang == "en" ? "Free Transactions" : "免手續費額度"
                  }}</span>
                  <i>
                    {{ lang == "en" ? "Remaining" : "剩餘" }}
                    {{ chargeDeduction }}&ensp;
                    <icon_gold class="icon" />
                  </i>
                </div>
                <div class="num">
                  <span class="label">{{ lang == "en" ? "Fee" : "手續費" }}</span>
                  <i>
                    <!-- {{ Math.round(asset_exchange_charge_rate_for_diamond_from/R*100)/100 }}&ensp; -->
                    {{ asset_exchange_charge_rate_for_diamond_from }}&ensp;
                    <icon_gold class="icon" />
                  </i>
                </div>
                <div class="num">
                  <span class="label">{{
                    lang == "en" ? "Estimated Arrival" : "預計到賬"
                  }}</span>
                  <i>
                    <!-- <span>{{ numVal / R - Math.round(asset_exchange_charge_rate_for_diamond_from / R * 100) / 100 || 0 }}&ensp;</span> -->
                    <span>{{ numVal / R || 0 }}&ensp;</span>
                    <icon_u class="icon" />
                  </i>
                </div>
                <div class="num">
                  <span class="label">{{
                    lang == "en" ? "Estimated Balance After" : "預計剩餘"
                  }}</span>
                  <i>
                    <span
                      >{{
                        props.gold -
                        (numVal || 0) -
                        asset_exchange_charge_rate_for_diamond_from
                      }}&ensp;</span
                    >
                    <icon_gold class="icon" />
                  </i>
                </div>
              </div>
              <ui_btn_1
                v-if="
                  numVal &&
                  props.modelValue == 2 &&
                  numVal <= props.gold * 1 &&
                  numVal > 0
                "
                class="btn"
                @click="exchange"
                :width="'100%'"
                :height="'0.48rem'"
                >{{ lang == "en" ? "Confirm Sell" : "確認兌出" }}</ui_btn_1
              >
              <ui_btn_1
                v-else
                class="btn"
                :disclick="true"
                :width="'100%'"
                :height="'0.48rem'"
                >{{ lang == "en" ? "Confirm Sell" : "確認兌出" }}</ui_btn_1
              >
            </template>
          </div>
        </div>
        <Chain_v2 v-else v-model="props.modelValue" class="block" />
        <div class="block">
          <list_table v-model="props.modelValue"></list_table>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, ref, watch, inject, onMounted } from "vue";
import { useStore } from "vuex";
import common from "../../utils/common";
import axios from "axios";
import IconBack from "../ui/icon/icon_back.vue";
// import Iput2 from "../ui/ui_input_2.vue";
import ui_btn_1 from "../ui/ui_btn_1.vue";
import Chain_v2 from "@/components/u/Chain_v2.vue";
import icon_gold from "@/components/ui/icon/icon_gold";
import icon_u from "@/components/ui/icon/icon_u";
// import icon_free from "@/components/ui/icon/icon_free";
import list_table from "./list_table.vue";

// 金幣的兌換實際上是金幣和鑽石的兌換（1U=1000鑽石）
const R = 1000;

let props = defineProps(["modelValue", "gold", "u"]);
let emites = defineEmits(["update:modelValue"]);

let store = useStore();
let lang = computed(() => {
  return store.state.lang;
});

// 刷新餘額
const queryFinance = inject("queryFinance");

// 用戶當前可減免手續費額度
let chargeDeduction = ref();
const getUserChargeDeduction = () => {
  axios
    .get(common.userApi.requestUrl + "/finance_api/user_charge_deduction")
    .then((r) => {
      if (r.data.code == 0) {
        // chargeDeduction.value = r.data.deduction / R;
        chargeDeduction.value = r.data.deduction;
      }
    });
};

// 金幣兌入兌換手續費 鑽石兌換到其他資產手續費率，百分制
let asset_exchange_charge_rate_for_diamond_to = computed(() => {
  let res;
  for (let i = 0; i < store.state.sysConfig.length; i++) {
    if (
      store.state.sysConfig[i].name == "asset_exchange_charge_rate_for_diamond_to" &&
      store.state.sysConfig[i].type == common.financeType.gold
    ) {
      res = store.state.sysConfig[i].value;
      break;
    }
  }
  if (numVal.value && props.modelValue == 1) {
    // 如果免手續費額度大於應支付的手續費，手續費為0
    if (chargeDeduction.value * R >= numVal.value * (res / 100)) {
      return 0;
    } else {
      return numVal.value * (res / 100) - chargeDeduction.value * R;
    }
  }
  return 0;
});
// 金幣兌出兌換手續費 其他資產兌換成鑽石手續費率，百分制
let asset_exchange_charge_rate_for_diamond_from = computed(() => {
  let res;
  for (let i = 0; i < store.state.sysConfig.length; i++) {
    if (
      store.state.sysConfig[i].name == "asset_exchange_charge_rate_for_diamond_from" &&
      store.state.sysConfig[i].type == common.financeType.gold
    ) {
      res = store.state.sysConfig[i].value;
      break;
    }
  }
  if (numVal.value && props.modelValue == 2) {
    // 如果免手續費額度大於應支付的手續費，手續費為0
    if (chargeDeduction.value * R >= numVal.value * (res / 100)) {
      return 0;
    } else {
      return numVal.value * (res / 100) - chargeDeduction.value * R;
    }
  }
  return 0;
});

// 兌換
let numVal = ref();
const exchange = () => {
  if (numVal.value > 0) {
    const sendUrl = common.userApi.requestUrl + "/finance_api/asset_converter";
    let sendData = {};
    // 兌入 從鑽石到金幣
    if (props.modelValue == 1) {
      sendData = common.buildSendData({
        from_asset_type: common.financeType.diamond,
        // from_value: Math.floor(numVal.value  / (1 - asset_exchange_charge_rate_for_diamond_to.value / numVal.value)),
        from_value: numVal.value * 1,
        to_asset_type: common.financeType.gold,
      });
    }
    //兌出 從金幣到鑽石
    else {
      sendData = common.buildSendData({
        from_asset_type: common.financeType.gold,
        from_value: numVal.value * 1,
        to_asset_type: common.financeType.diamond,
      });
    }
    const sendHeaders = common.buildHeaders("form");
    common.showLoading("u exchange");
    axios.post(sendUrl, sendData, { headers: sendHeaders }).then((r) => {
      if (r.data.code == 0) {
        toastr["success"](r.data.msg);
      } else if (r.data.code == 222) {
        toastr["error"]("Not Enough U Coin!");
      } else {
        toastr["error"](r.data.msg);
      }
      common.hideLoading("u exchange");
      queryFinance();
      numVal.value = null;
    });
  }
};

// 校驗兌入兌出金額
const limitInputNum = ($event) => {
  /* console.log($event)
    if ($event.key == 2) {
        $event.preventDefault();
        return false;
    } */
  if (props.modelValue == 1) {
    // if (numVal.value > props.u*R) {
    //     // $event.preventDefault();
    //     // return false;
    //     numVal.value = props.u * R;
    // }
  } else if (props.modelValue == 2) {
    if (numVal.value > props.gold * 1) {
      // $event.preventDefault();
      // return false;
      numVal.value = props.gold;
    }
  }
};

onMounted(() => {
  getUserChargeDeduction();
});
</script>
<style lang="scss" scoped src="./exchange_v2.scss"></style>
