<template>
  <div class="template u_component">
    <MyWallet v-if="!activeIndex || activeIndex == 0"></MyWallet>
    <MyWalletLog v-else-if="activeIndex == 1"></MyWalletLog>
    <Transfer v-else-if="activeIndex == 2"></Transfer>
  </div>
</template>
<style lang="scss" scoped>
.u_component {
  min-height: 100%;
}
</style>
<script setup>
import MyWallet from "@/components/u/MyWallet.vue";
import MyWalletLog from "@/components/u/MyWalletLog.vue";
import Transfer from "@/components/u/Transfer.vue";
import { computed } from "vue";
import { useRouter } from "vue-router";
import common from "../../utils/common";

let router = useRouter();
let activeIndex = computed(() => {
  return router.currentRoute.value.query.activeindex;
});
</script>
