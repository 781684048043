<template>
    <div class="template detail">
        <div class="caption" v-if="!props.detailItem.hideBackArrow">
            <span class="back" @click="$emit('update:modelValue', null)">
                <IconBack /><span>{{ lang == 'en' ? 'Go Back' : '返回' }}</span>
            </span>
        </div>
        <div class="detail_title" v-if="!props.detailItem.hideBackArrow">{{ lang == 'en' ? 'Transaction Detail' : '訂單詳情' }}
        </div>
        <div class="content">
            <ul :data-type="props.type">
                <li>
                    <span class="label">{{ lang == 'en' ? 'Type' : '交易類型' }}</span><span
                        class="val">{{ formatType(props.type) }}</span>
                </li>
                <li>
                    <span class="label">{{ lang == 'en' ? 'Currency' : '區塊鏈類型' }}</span><span class="val">{{common.chain_tp[props.detailItem.chain_tp]}}</span>
                </li>
                <li>
                    <span class="label">{{ lang == 'en' ? 'Network' : '鏈幣支付類型' }}</span><span class="val">{{
                            props.detailItem.chain_payment_name
                    }}</span>
                </li>
                <li>
                    <span class="label">{{ lang == 'en' ? 'Currency' : '幣種' }}</span><span class="val">{{
                            props.detailItem.chain_currency == 1 ? 'USDT' : 'WU'
                    }}</span>
                </li>
                <li class="hr" v-show="props.detailItem.status != 2"></li>
                <li v-show="props.detailItem.status != 2">
                    <span class="label">{{ lang == 'en' ? 'Amount' : formatType(props.type) + '金額' }}</span>
                    <span class="val red">{{ props.detailItem.asset_count / 1000 || (Math.abs(props.detailItem.op_value)
                            - props.detailItem.pay_charge) / 1000
                    }}</span>
                    <span>&ensp;
                        <Icon_u class="icon" />
                    </span>
                </li>
                <li v-show="props.type == 3 && props.detailItem.status != 2">
                    <span class="label">{{ lang == 'en' ? 'Amount' : '轉賬金額' }}</span>
                    <span v-if="(props.detailItem.chain_tp == 2)" class="val red">
                        <!-- {{ common.formatCount18Float(props.detailItem.transfernum || props.detailItem.count)}} -->
                        {{ props.detailItem.short_count }}
                        {{props.detailItem.chain_currency == 1 ? ' USDT' : ' WU'}}
                    </span>
                    <span v-else class="val red">
                        <!-- {{ common.formatCount6Float(props.detailItem.transfernum ||props.detailItem.count)}} -->
                        {{ props.detailItem.short_count }}
                        {{ props.detailItem.chain_currency == 1 ? ' USDT' : ' WU' }}
                    </span>
                </li>
                <li v-show="props.type == 3 && props.detailItem.status != 2">
                    <span class="label">{{ lang == 'en' ? 'Address' : '錢包地址' }}</span><span class="val red">{{
                            props.detailItem.systemAccount || systemAccount
                    }}</span>
                </li>
                <li v-if="props.type == 4">
                    <span class="label">{{ lang == 'en' ? 'Address' : '錢包地址' }}</span><span class="val red">{{
                            props.detailItem.chain_account
                    }}</span>
                </li>
                <li v-if="props.type == 4">
                    <span class="label" :data-charge="props.detailItem.charge">{{ lang == 'en' ? 'Fee' : '手續費' }}</span><span class="val">{{ props.detailItem.charge / 1000
                    }}</span><span>&ensp;
                        <Icon_u class="icon" />
                    </span>
                </li>
                <li class="hr"></li>
                <li><span class="label">{{ lang == 'en' ? 'Submission Time' : '提交時間' }}</span><span
                        class="val">{{ common.formatDateTime(props.detailItem.add_time) }}</span></li>
                <li><span class="label">{{ lang == 'en' ? 'Status' : '狀態' }}</span><span
                        :class="{ val: true,color: true, orange: props.detailItem.status == 0, green: props.detailItem.status == 1, red: props.detailItem.status == 2 }">{{
                                formatChainStatus(props.detailItem.status)
                        }}</span></li>
                <li v-if="props.detailItem.status == 1" class="hr"></li>
                <li v-if="props.detailItem.status == 1">
                    <span class="label">{{ lang == 'en' ? 'Action' : '操作' }}</span>
                    <UiButton @click="showFeebackTag = true" width="0.66rem" height="0.33rem" fontsize="0.18rem"
                        lineHeight="0.33rem">{{ lang == 'en' ? 'Arbitration' : '仲裁' }}</UiButton>
                </li>
            </ul>
            <div class="hr" :data-type="props.type" :data-status="props.detailItem.status"></div>
            <div class="bot" v-if="props.detailItem.status == 0 || props.detailItem.status == 2">                   
                <div class="qrc" v-if="props.type==3 && props.detailItem.status == 0">
                    <QrcodeVue :value="qrcData" :size="256" level="H" />
                </div>
                <template v-if="(props.type==3)">
                    <p class="tips" v-if="props.detailItem.status == 0"><i>{{ lang=='en'?'Wallet Pay QR Code':'錢包付款二維碼' }}</i><span>*</span> {{ lang == 'en' ? 'Reminder: Please make a transfer to the listed address within 30 minutes.Attempts after that time period will not be accepted. Transfer amount must match designated amount or despoit cannot be confirmed.':'提示：請在30分鐘內轉賬到指定錢包地址，超過時間後請勿再轉賬，否則無法到賬。指定金額必須一致，否者無法確認金額。'}}</p>
                    <p class="tips" v-if="props.detailItem.status == 2"><span>*</span> {{ lang == 'en' ? 'Reminder: This transaction has expired.Any amount further transferred cannot be confirmed.':'提示：該訂單已經超時，請勿再轉賬，否者金額無法確認。'}}</p> 
                </template>
                <p class="tips" v-else><span>*</span> {{ lang == 'en' ? 'Reminder: Withdraw may take longer than expected due to network delays.':'提示：提現會存在延時到賬情況，需根據鏈路情況決定。'}}</p>
                <p class="tips">
                    <span>*</span>
                    {{
                      lang == "en"
                        ? "Please note that only decentralised wallets are supported for trading operations, using exchange wallets (Coin, Fire, Ouyi, pexpay) may lead to unforeseen consequences <we recommend using decentralised wallets such as metamask, TokenPocket, imToken, etc. for operations>."
                        : "請注意只支持使用去中心化錢包進行交易操作 ，如使用交易所錢包（幣安、火幣、歐易、pexpay），將可能導致不可預期的後果<建議使用metamask、TokenPocket、imToken等去中心化錢包進行操作>"
                    }}
                  </p>
            </div>
        </div>
        <div v-if="showFeebackTag" class="feedback">
            <div class="box">
                <div class="top">{{ lang == 'en' ? 'Arbitration' : '仲裁' }}</div>
                <h2><span>{{ lang == 'en' ? 'Description' : '內容描述' }}</span></h2>
                <textarea v-model="content"></textarea>
                <div class="btn">
                    <span @click="showFeebackTag = false">{{ lang == 'en' ? 'Cancel' : '取消' }}</span>
                    <span class="confirm" @click="sendFeedback">{{ lang == 'en' ? 'Submit' : '提交' }}</span>
                </div>
                <IconClose class="iclose" @click="showFeebackTag = false" />
            </div>
        </div>
    </div>
</template>
<script setup>
import common from '../../utils/common';
import IconBack from '../ui/icon/icon_back.vue';
import UiButton from '../ui/ui_btn_2.vue';
import { ref, inject, computed, watch, onMounted } from 'vue';
import IconClose from "../ui/icon/icon_close.vue";
import Icon_u from "../ui/icon/icon_u.vue";
import axios from 'axios';
import { useStore } from 'vuex';
import QrcodeVue from 'qrcode.vue'

let store = useStore();
let lang = computed(() => {
    return store.state.lang;
})
let props = defineProps(['detailItem', 'type']);

let formatChainStatus = inject('formatChainStatus');

console.log(props.detailItem);
const formatType = (t) => {
    let res;
    switch (t) {
        case 1:
            if (lang.value == 'en') {
                res = 'Buy Gold';
            }
            else {
                res = '金幣兌入';
            }
            break;
        case 2:
            if (lang.value == 'en') {
                res = 'Sell Gold';
            }
            else {
                res = '金幣兌出';
            }
            break;
        case 3:
            if (lang.value == 'en') {
                res = 'Deposit';
            }
            else {
                res = '充值';
            }
            break;
        case 4:
            if (lang.value == 'en') {
                res = 'Withdraw'
            }
            else {
                res = '提現';
            }
            break;
        default:
            res = '';
            break;
    }
    return res;
}

// 系統錢包地址，充值時用
let systemAccount = ref('-');
// 充值和提現的渠道信息
const channel_infos = ref();
const getChannelInfos = () => {
    const sendUrl = common.userApi.requestUrl + '/finance_api/bwallet/channel_infos';
    const sendHeader = common.buildHeaders('form');
    axios.get(sendUrl, { headers: sendHeader })
        .then((r) => {
            if (r.data.code == 0) {
                channel_infos.value = r.data.infos;
            }
        })
}
getChannelInfos();
watch(channel_infos, (newVal, oldVal) => {
    if (newVal && newVal.length > 0) {
        for (let i = 0; i < newVal.length; i++) {
            if (props.detailItem.chain_tp == newVal[i].chain_tp) {
                for (let j = 0; j < newVal[i].chain_currency_infos.length; j++) {
                    if (props.detailItem.chain_currency == newVal[i].chain_currency_infos[j].currency) {
                        systemAccount.value = newVal[i].chain_currency_infos[j].system_account;
                        return;
                    }
                }
            }
        }
        systemAccount.value = '.';
    }
    else {
        systemAccount.value = '-';
    }
})


//是否顯示仲裁
let showFeebackTag = ref(false);
let content = ref();
const sendFeedback = () => {
    if (!content.value) {
        toastr['error']('Please Input Content Description.');
        return;
    }
    const sendUrl = common.userApi.requestUrl + '/misc_api/user_reports';
    const sendData = common.buildSendData({
        type: 4,
        content: content.value,
        additional_data: JSON.stringify(props.detailItem)
    });
    axios.put(sendUrl, sendData, { header: common.buildHeaders('form') }).then((r) => {
        if (r.data.code == 0) {
            toastr['success'](r.data.msg);
            showFeebackTag.value = false;
        }
        else {
            toastr['error'](r.data.msg);
        }
    })
};

// 充值二維碼
let qrcData = ref(props.detailItem.systemAccount || systemAccount);
</script>
<style lang="scss" scoped src="./Detail.scss"></style>
