<script setup>
import { ref, computed, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import ui_btn_1 from "../../ui/ui_btn_1.vue";
import icon_trx from "@/components/ui/icon/icon_trx";
import icon_usdt from '@/components/ui/icon/icon_usdt';
import icon_gold from '@/components/ui/icon/icon_gold';
import Iput2 from "../../ui/ui_input_2.vue";
import common from "../../../utils/common";
import { WALLET_TOKEN_TYPE, exchangeGoldFromToken, getExchangeGoldOrders } from "../../../utils/walletTools";
import list_items from "./Token2GoldItems.vue";

const store = useStore();
const langs = computed(() => {
  if (store.state.lang === "en") {
    return {
      usdtBalance: "USDT Balance",
      trxBalance: "TRX Balance",

      selectToken: "Token",
      amount: "Amount",
      amountPlaceholder: "Please enter the amount to be exchange",
      exchange: "Exchange",

      tips1: "Tip: Each TRX transfer consumes 269 Bandwidth. If the bandwidth is insufficient, TRX will be deducted as a handling fee.",
      tips2: "Tip: 269 Bandwidth = 0.269 TRX。",
      tips3: "Tip: Each USDT transfer consumes 345 Bandwidth. If the recipient's address has USDT, it will consume 31895 Energy; If not, it will consume 64895 Energy. If the bandwidth and Energy are insufficient, TRX will be deducted as a handling fee.",
      tips4: "Tip: 345 Bandwidth = 0.345 TRX、31895 Energy ≈ 13.7 TRX、64895 Energy ≈ 17.16TRX。",
      tips5: "Tip: If there is insufficient energy, renting energy can save on transaction fees.",
      tips6: "Tip: here is a possibility of delay and failure in on chain transactions. You can go to the blockchain browser to view transaction details.",

      platform1: "Rental Platform 1",
      platform2: "Rental Platform 2",
      chainWeb: "Blockchain Browser",

      getGoldAmout: "Amount of gold obtained",
      tipsSuccess: "The exchange request has been submitted!",

      tipsUseTrx: "Recommend using TRX",
    }
  } else {
    return {
      usdtBalance: "USDT餘額",
      trxBalance: "TRX餘額",

      selectToken: "消耗鏈幣種類",
      amount: "消耗鏈幣數量",
      amountPlaceholder: "請輸入兌換數量",
      gas: "預計燃油費",
      exchange: "兌換",

      tips1: "提示：每轉一筆TRX會消耗 269 頻寬。 如果頻寬不足，會扣除TRX作為手續費。",
      tips2: "提示：269 頻寬 = 0.269 TRX。",
      tips3: "提示：每轉一筆USDT會消耗 345 頻寬。 如果收款方地址有USDT，會消耗 31895 能量； 如果沒有，會消耗 64895 能量。 如果頻寬和能量不足，會扣除TRX作為手續費。",
      tips4: "提示：345 頻寬 = 0.345 TRX、31895 能量 ≈ 13.4 TRX、64895 能量 ≈ 27.3 TRX。",
      tips5: "提示：如果能量不足，租賃能量能够節省手續費。",
      tips6: "提示：鏈上交易有延時和失敗的可能，您可以前往區塊鏈瀏覽器查看交易詳情。",

      platform1: "租賃平臺1",
      platform2: "租賃平臺2",
      chainWeb: "區塊鏈瀏覽器",

      getGoldAmout: "預計獲得金幣數量",

      tipsSuccess: "兌換請求已經提交！",

      tipsUseTrx: "推薦使用TRX",
    }
  }
});

//定于余额
const usdtBalance = defineModel("usdt");
const trxBalance = defineModel("trx");
const props = defineProps(["addressUrl", "trxUsdtPrice"]);

//选中发送的币
const curToken = ref(WALLET_TOKEN_TYPE.USDT);

//输入
const inputAmount = ref("");
const orders = ref([]);
const toAmount = computed(() => {
  const tokenAmount = parseFloat(inputAmount.value);
  if (isNaN(tokenAmount)) {
    return 0;
  } else {
    if (curToken.value === WALLET_TOKEN_TYPE.USDT) {
      return Math.floor(tokenAmount * 1000);
    } else {
      return Math.floor(tokenAmount * 1000 * props.trxUsdtPrice);
    }
  }
});

const disClick = computed(() => {
  if (toAmount.value === 0) {
    return true;
  }

  if (curToken.value === WALLET_TOKEN_TYPE.USDT && parseFloat(inputAmount.value) > parseFloat(usdtBalance.value)) {
    return true;
  }

  if (curToken.value === WALLET_TOKEN_TYPE.TRX && parseFloat(inputAmount.value) > parseFloat(trxBalance.value)) {
    return true;
  }

  return false;
});


//提交兑换请求
async function requestExchange() {
  try {
    common.showLoading("exchange token");
    await exchangeGoldFromToken(curToken.value, inputAmount.value);
    toastr['success'](langs.value.tipsSuccess);

    inputAmount.value = "";
  } catch (error) {
    toastr['error'](error.message);
  }
  common.hideLoading("exchange token");
}

//获取转账订单信息
async function loadOrders() {
  try {
    orders.value = await getExchangeGoldOrders();
  } catch (error) {
    toastr['error'](error.message);
  }
}

let timer = null;
onMounted(() => {
  loadOrders();
  timer = setInterval(loadOrders, 10000);
})

onUnmounted(() => {
  clearInterval(timer);
})

</script>
<style lang="scss" src="./SendToken.scss"></style>
<template>
  <div class="send_usdt">
    <!--余额-->
    <div class="block item_balance">

      <!--余额usdt-->
      <div>
        <h2>{{ langs.usdtBalance }}</h2>
        <div class="item_balance_content">
          <div class="token_bg">
            <icon_usdt />
          </div>
          <div class="item_balance_count">{{ usdtBalance }}</div>
        </div>
      </div>

      <!--余额trx-->
      <div>
        <h2>{{ langs.trxBalance }}</h2>
        <div class="item_balance_content">
          <div class="token_bg">
            <icon_trx />
          </div>
          <div class="item_balance_count">{{ trxBalance }}</div>
        </div>
      </div>
    </div>

    <!--用户输入-->
    <div class="template u_chain block">
      <div class="main">
        <!--链币种类选择-->
        <dl>
          <dt>{{ langs.selectToken }}
            <span class="tips_title">（{{ langs.tipsUseTrx }}）</span>
          </dt>
          <dd :class="{ on: curToken === WALLET_TOKEN_TYPE.USDT }" @click="curToken = WALLET_TOKEN_TYPE.USDT">
            <span>
              <icon_usdt />&nbsp;USDT
            </span>
          </dd>
          <dd :class="{ on: curToken === WALLET_TOKEN_TYPE.TRX }" @click="curToken = WALLET_TOKEN_TYPE.TRX">
            <span>
              <icon_trx />&nbsp;TRX
            </span>
          </dd>
        </dl>

        <!--兑换数量-->
        <dl>
          <dt>{{ langs.amount }}</dt>
          <dd class="inputbox">
            <Iput2 :placeholder="langs.amountPlaceholder" width="100%" :backgroundcolor="'#1B203F'" :heigth="'.48rem'"
              v-model="inputAmount" :type="'number1'" />
            <icon_trx v-if="curToken === WALLET_TOKEN_TYPE.TRX" class="icon" />
            <icon_usdt v-else class="icon" />
          </dd>
        </dl>

        <!--预计得到的数量-->
        <div class="estimated">
          <span class="title">{{ langs.getGoldAmout }}</span>
          <span class="amount">{{ toAmount }}
            <icon_gold class="icon" />
          </span>
        </div>

        <br>
        <div class="btnbox">
          <ui_btn_1 :width="'8rem'" :height="'0.48rem'" :disclick="disClick" @click="requestExchange">
            {{ langs.exchange }}
          </ui_btn_1>

          <!--提示信息-->
          <div class="hr"></div>
          <template v-if="curToken === WALLET_TOKEN_TYPE.TRX">
            <p class="tips"><span>*</span> {{ langs.tips1 }}</p>
            <p class="tips"><span>*</span> {{ langs.tips2 }}</p>
          </template>
          <template v-else>
            <p class="tips"><span>*</span> {{ langs.tips3 }}</p>
            <p class="tips"><span>*</span> {{ langs.tips4 }}</p>
            <p class="tips">
              <span>*</span>
              {{ langs.tips5 }}
              &nbsp;
              <a target="_blank" href="https://www.trxneng.com/">{{ langs.platform1 }}</a>
              &nbsp;
              <a target="_blank" href="http://diditron.com/home">{{ langs.platform2 }}</a>
            </p>
          </template>
          <p class="tips">
            <span>*</span>
            {{ langs.tips6 }}
            &nbsp;
            <a target="_blank" :href="addressUrl">{{ langs.chainWeb }}</a>
          </p>
        </div>

      </div>
    </div>

    <!--历史记录-->
    <div class="block">
      <list_items :infos="orders"></list_items>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.template.u_chain {
  padding: 0.275rem 0.6rem;
  min-height: 100vh;
  min-height: auto;

  .main {
    .estimated {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .title {
        margin: 0;
        padding: 0;

        color: rgba($color: #fff, $alpha: 0.4);
        font-size: 0.14rem;
      }

      .amount {
        margin: 0;
        padding: 0;

        color: #fff;
        font-size: 0.14rem;

        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
  }
}

@media screen and (max-width: 1059px) {
  .template.u_chain {
    .main {
      width: 100%;
    }
  }
}
</style>