<script setup>
import { ref, computed, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import sub_page_container from "./SubPageContainer.vue";
import send_token from "./SendToken.vue";
import token_2_gold from "./Token2Gold";
import gold_2_token from "./Gold2Token";
import icon_red_triangle from "@/components/ui/icon/icon_red_triangle.vue";
import loading_item from "../../loading/loading_item.vue";
import ui_btn_1 from "../../ui/ui_btn_1.vue";
import ui_btn_4 from "../../ui/ui_btn_4.vue";
import common from "@/utils/common";
import { getWalletInfo, getWalletBalance, getTrxUsdtPrice, createWallet, WALLET_TOKEN_TYPE } from "../../../utils/walletTools";
import icon_trx from "@/components/ui/icon/icon_trx";
import icon_usdt from '@/components/ui/icon/icon_usdt';
import useClipboard from "vue-clipboard3";

//金币余额
defineProps(["goldBalance"]);

const store = useStore();
const langs = computed(() => {
  if (store.state.lang === "en") {
    return {
      title: "Wallet On-Chain(TRON)",
      tips_1: "You don't have an on chain wallet yet",
      tips_2: "Click to apply for a wallet to obtain a free on chain wallet address",
      createBtn: "Create an On-Chain wallet",

      refreshBtn: "Refresh",
      sendBtn: "Send Token",
      token2GoldBtn: "Token For Gold",
      gold2TokenBtn: "Gold For Token",

      copyTips: "Address copied successfully!",

      energy: "Energy",
      bandwidth: "Bandwidth",
    }
  } else {
    return {
      title: "鏈上錢包(TRON)",
      tips_1: "您還沒有鏈上錢包",
      tips_2: "點擊申請錢包，即可免費獲得鏈上錢包地址",
      createBtn: "創建鏈上錢包",

      refreshBtn: "刷新數據",
      sendBtn: "發送鏈幣",
      token2GoldBtn: "鏈幣換金幣",
      gold2TokenBtn: "金幣換鏈幣",

      copyTips: "地址複製成功！",
      energy: "能量",
      bandwidth: "頻寬",
    }
  }
});

const loaded = ref(false); //数据是否加载完成
const created = ref(false); //是否创建钱包
const address = ref(""); //钱包信息
const addressActivated = ref(false);

//余额
const usdtAmount = ref("0");
const trxAmount = ref("0");
const totalBandwidth = ref("0");
const totalEnergy = ref("0");
const bandwidth = ref("0");
const energy = ref("0");
const trxUsdtPrice = ref(0.14);

//子页面
const SUB_PAGE_TYPE = {
  NULL: 0,
  SEND_TOKEN: 1,
  TOKEN_2_GOLD: 2,
  GOLD_2_TOKEN: 3,
}//

const curSubPage = ref(SUB_PAGE_TYPE.NULL);
const subPageTitle = ref("");

//浏览器地址
const addressHref = computed(() => {
  if (common.isProduction) {
    return `https://tronscan.org/#/address/${address.value}`;
  } else {
    return `https://shasta.tronscan.org/#/address/${address.value}`;
  }
})

//显示子页面
function showSubPage(subPageType, pageTitle) {
  curSubPage.value = subPageType;
  subPageTitle.value = pageTitle;
}

//隐藏子页面
function hideSubPage() {
  curSubPage.value = SUB_PAGE_TYPE.NULL;
  subPageTitle.value = "";
}

//定时刷新余额
let updateBalanceTimer = null;

async function updateBalance() {
  try {
    const { trxBalance, usdtBalance, activated, totalBandwidth: tb, totalEnergy: te, bandwidth: b, energy: e } = await getWalletBalance(WALLET_TOKEN_TYPE.USDT);
    usdtAmount.value = usdtBalance;
    trxAmount.value = trxBalance;
    addressActivated.value = activated;
    totalBandwidth.value = tb;
    totalEnergy.value = te;
    bandwidth.value = b;
    energy.value = e;
  } catch (error) {
    toastr['error']("Load balance failed:" + error.message);
  }
}

async function updateTrxUsdtPrice() {
  try {
    const trx = await getTrxUsdtPrice();
    trxUsdtPrice.value = trx;
  } catch (error) {
    toastr['error']("Load TRXUSDT price failed:" + error.message);
  }
}

async function startUpdateBalanceTimer() {
  //失败了问题不大
  Promise.all([
    updateBalance(),
    updateTrxUsdtPrice()
  ]);

  updateBalanceTimer = setInterval(() => {
    updateBalance();
    updateTrxUsdtPrice();
  }, 30000);
}

//创建钱包
async function requsetCreateWallet() {
  try {
    const info = await createWallet();
    created.value = true;
    address.value = info;

    //更新钱包余额信息
    startUpdateBalanceTimer();
  } catch (error) {
    toastr['error']("Create wallet failed:" + error.message);
  }
}

//加载钱包信息
onMounted(async () => {
  try {
    const info = await getWalletInfo();
    if (info) {
      //创建了钱包
      created.value = true;
      address.value = info;

      //更新钱包余额信息
      await startUpdateBalanceTimer();
    } else {
      //没有创建钱包
      created.value = false;
    }
    loaded.value = true;
  } catch (error) {
    toastr['error']("Load wallet info failed:" + error.message);
  }
})

onUnmounted(() => {
  if (updateBalanceTimer) {
    clearInterval(updateBalanceTimer);
    updateBalanceTimer = null;
  }

  //destoryInstance();
})

async function copyAcount() {
  const { toClipboard } = useClipboard();
  await toClipboard(address.value);
  toastr["success"](langs.value.copyTips);
}

</script>
<style lang="scss" src="./WalletOnChain.scss"></style>
<template>
  <div class="wallet_on_chain">
    <div class="title">
      <icon_red_triangle />{{ langs.title }}
    </div>
    <div class="content_container">
      <!--数据加载中-->
      <loading_item v-if="!loaded" />

      <!--数据加载完成，但是没有创建钱包-->
      <div class="create_wallet" v-else-if="!created">
        <span class="tips">
          {{ langs.tips_1 }}
          <br>
          {{ langs.tips_2 }}
        </span>
        <ui_btn_1 class="btn" :width="'2.8rem'" :height="'0.48rem'" @click="requsetCreateWallet">
          {{ langs.createBtn }}
        </ui_btn_1>
      </div>

      <!--数据加载完成，已经创建钱包-->
      <div class="wallet_info" v-else>
        <div class="address">
          <a target="_blank" :href="addressHref">{{ address }}</a>
          <button class="copy_btn" @click="copyAcount"></button>
        </div>

        <div class="amounts">
          <!--usdt-->
          <div class="amount amount_item">
            <div class="token_bg">
              <icon_usdt />
            </div>
            <div class="info">
              <div class="title">USDT</div>
              <div class="count">{{ usdtAmount }}</div>
            </div>
          </div>

          <!--trx-->
          <div class="amount amount_item">
            <div class="token_bg">
              <icon_trx />
            </div>
            <div class="info">
              <div class="title">TRX</div>
              <div class="count">{{ trxAmount }}</div>
            </div>
          </div>

          <!--trx-->
          <div class="resources">
            <div class="resource">
              <h2>{{ langs.energy }}</h2>
              <div>{{ energy }} <span>&nbsp/&nbsp{{ totalEnergy }}</span></div>
            </div>

            <div class="resource">
              <h2>{{ langs.bandwidth }}</h2>
              <div>{{ bandwidth }} <span>&nbsp/&nbsp{{ totalBandwidth }}</span></div>
            </div>
          </div>
        </div>

        <div class="ops">
          <ui_btn_4 :width="'1.2rem'" @click="showSubPage(SUB_PAGE_TYPE.SEND_TOKEN, langs.sendBtn)">
            {{ langs.sendBtn }}
          </ui_btn_4>

          <ui_btn_1 :width="'1.44rem'" :height="'0.48rem'"
            @click="showSubPage(SUB_PAGE_TYPE.TOKEN_2_GOLD, langs.token2GoldBtn)">
            {{ langs.token2GoldBtn }}
          </ui_btn_1>
          <ui_btn_1 :width="'1.44rem'" :height="'0.48rem'"
            @click="showSubPage(SUB_PAGE_TYPE.GOLD_2_TOKEN, langs.gold2TokenBtn)">
            {{ langs.gold2TokenBtn }}
          </ui_btn_1>
        </div>
      </div>
    </div>
  </div>

  <!--二级窗口-->
  <sub_page_container v-if="curSubPage !== SUB_PAGE_TYPE.NULL" @back-btn-click="hideSubPage" :title="subPageTitle">
    <send_token v-if="curSubPage === SUB_PAGE_TYPE.SEND_TOKEN" v-model:usdt="usdtAmount" v-model:trx="trxAmount"
      :addressUrl="addressHref">
    </send_token>

    <token_2_gold v-if="curSubPage === SUB_PAGE_TYPE.TOKEN_2_GOLD" v-model:usdt="usdtAmount" v-model:trx="trxAmount"
      :addressUrl="addressHref" :trxUsdtPrice="trxUsdtPrice">
    </token_2_gold>

    <gold_2_token v-if="curSubPage === SUB_PAGE_TYPE.GOLD_2_TOKEN" :goldBalance="goldBalance" v-model:usdt="usdtAmount"
      :addressUrl="addressHref" :addressActivated="trxAmount !== 0" :trxUsdtPrice="trxUsdtPrice">
    </gold_2_token>
  </sub_page_container>
</template>