<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import IconBack from "../../ui/icon/icon_back.vue";

//定义
defineProps(['title']);
const emit = defineEmits(["backBtnClick"]);
const store = useStore();

</script>
<style lang="scss" src="./SubPageContainer.scss"></style>

<template>
  <div class="sub_page_container">

    <!--标题栏-->
    <div class="caption">
      <div class="block_item">
        <!--标题-->
        <div class="detail_title onlypc">
          <span>{{ title }}</span>
        </div>

        <!--返回-->
        <span class="back" @click="emit('backBtnClick');">
          <IconBack />
          <span class="onlypc">{{ store.state.lang == "en" ? "Go Back" : "返回" }}</span>
          <span class="onlymobile">{{ title }}</span>
        </span>
      </div>
    </div>

    <div class="sub_page">
      <div class="block_item">
        <slot></slot>
      </div>
    </div>
  </div>
</template>