<template>
  <div class="template transfer">
    <div class="finance">
      <div class="ico">
        <icon_u />
      </div>
      <div class="data">
        <h2>{{ lang == "en" ? "U Amount" : "U幣數量" }}</h2>
        <div>{{ userU }}</div>
      </div>
    </div>
    <div class="content">
      <div class="num">
        <div class="label">{{ lang == "en" ? "Recipient" : "轉賬對象" }}</div>
        <input
          :placeholder="lang == 'en' ? 'Please Enter Recipient UID' : '請輸入收款方UID'"
          v-model="toUid"
          type="number"
          @blur="getToUserInfo"
        />
      </div>
      <div class="touser" v-if="toNick && toAvatar && toUid">
        <img class="avatar" :src="toAvatar" alt="" /> <span>{{ toNick }}</span>
      </div>
      <p v-else class="tips">
        <span>*</span> {{ lang == "en" ? "Unable to Get User Info" : "無此用戶信息" }}
      </p>
      <div class="num">
        <div class="label">{{ lang == "en" ? "Amount" : "轉賬金額" }}</div>
        <Iput2
          class="onlypc"
          :placeholder="
            lang == 'en' ? 'Please Enter Sending Amount' : '請輸入轉給對方的金額'
          "
          :width="'6rem'"
          :backgroundcolor="'#1B203F'"
          :height="'.48rem'"
          v-model="toNum"
          :type="'number'"
        />
        <Iput2
          class="onlymobile"
          :placeholder="
            lang == 'en' ? 'Please Enter Sending Amount' : '請輸入轉給對方的金額'
          "
          :width="'100%'"
          :backgroundcolor="'#26254C'"
          :height="'.88rem'"
          v-model="toNum"
          :type="'number'"
        />
        &ensp;
        <icon_u class="icon" />
      </div>
      <div class="num transfer_fee">
        <div class="label">{{ lang == "en" ? "Fee" : "手續費" }}</div>
        &ensp;
        <icon_free
          class="icon free"
          v-if="send_asset_change_rate && send_asset_change_rate.value == 0"
        />
        <span class="val" v-else-if="send_asset_change_rate"
          >{{ send_asset_change_rate.value || 0 }}<icon_gold
        /></span>
      </div>
      <div class="btnbox onlypc">
        <ui_btn_1
          v-if="toNick && toAvatar && toUid && toNum"
          class="btn"
          @click="showTransferTag = true"
          :width="'1.67rem'"
          :height="'0.48rem'"
          >{{ lang == "en" ? "Send" : "轉賬" }}</ui_btn_1
        >
        <ui_btn_1
          v-else
          class="btn"
          :disclick="true"
          :width="'1.67rem'"
          :height="'0.48rem'"
          >{{ lang == "en" ? "Send" : "轉賬" }}</ui_btn_1
        >
      </div>
      <div class="hr"></div>
      <p class="tips">
        <span>*</span>
        {{
          lang == "en"
            ? "Reminder: Transaction cannot be backtracked once started. Please carefully verify transaction detail."
            : "提示：轉賬不可撤回，請仔細核對相關數據！"
        }}
      </p>
      <div class="btnbox onlymobile">
        <ui_btn_1
          v-if="toNick && toAvatar && toUid && toNum"
          class="btn"
          @click="showTransferTag = true"
          :width="'1.67rem'"
          :height="'0.48rem'"
          >{{ lang == "en" ? "Send" : "轉賬" }}</ui_btn_1
        >
        <ui_btn_1
          v-else
          class="btn"
          :disclick="true"
          :width="'1.67rem'"
          :height="'0.48rem'"
          >{{ lang == "en" ? "Send" : "轉賬" }}</ui_btn_1
        >
      </div>
    </div>
    <div class="confirmbox">
      <Confirm
        :again="confirmAgain"
        v-model="showTransferTag"
        :title="lang == 'en' ? 'Confirm Transaction' : '確認轉賬'"
        :confirmtxt="lang == 'en' ? 'Confirm' : '確認轉賬'"
        :canceltxt="lang == 'en' ? 'Cancel' : '取消'"
        @confirm="transfer"
      >
        <ul class="content">
          <li>
            <span class="label">{{ lang == "en" ? "Recipient" : "轉賬對象" }}</span
            ><span class="val"
              ><img class="avatar" :src="toAvatar" alt="" />
              <span>{{ toNick }}</span></span
            >
          </li>
          <li>
            <div class="label">{{ lang == "en" ? "Amount" : "轉賬金額" }}</div>
            {{ toNum }}&ensp;
            <icon_u class="icon" />
          </li>
          <li>
            <div class="label">{{ lang == "en" ? "Fee" : "手續費" }}</div>
            &ensp;
            <icon_free
              class="icon free"
              v-if="send_asset_change_rate && send_asset_change_rate.value == 0"
            />
            <span class="val" v-else-if="send_asset_change_rate"
              >{{ send_asset_change_rate.value || 0 }}
              <icon_gold />
            </span>
          </li>
        </ul>
        <div v-if="showTransferTag && confirmAgain" class="again">
          <div class="box">
            <p v-if="lang == 'en'">
              {{
                lang == "en"
                  ? "Target address and transaction amount are identical to a recent transaction. Transaction cannot be backtracked once started.Please carefully verify transaction detail.Are you sure to make this transaction again?"
                  : "目標賬號及金額與最近一次轉賬相同，轉賬不可撤回，請仔細核對相關數據！您確定要重複轉賬嗎？"
              }}
            </p>
            <div class="btn">
              <span class="border" @click="showTransferTag = false">{{
                lang == "en" ? "Cancel" : "取消"
              }}</span>
              <span class="confirm" @click="transfer(1)">{{
                lang == "en" ? "Confirm Identical Transaction" : "確定重複轉賬"
              }}</span>
            </div>
          </div>
        </div>
        <p class="tips">
          <span>*</span>
          {{
            lang == "en"
              ? "Reminder: Transaction cannot be backtracked once started. Please carefully verify transaction detail."
              : "提示：轉賬不可撤回，請仔細核對相關數據！"
          }}
        </p>
      </Confirm>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import common from "../../utils/common.js";
import icon_u from "@/components/ui/icon/icon_u.vue";
import icon_gold from "@/components/ui/icon/icon_gold";
import Iput2 from "../ui/ui_input_2.vue";
import ui_btn_1 from "../ui/ui_btn_1.vue";
import icon_free from "@/components/ui/icon/icon_free";
import axios from "axios";
import Confirm from "../ui/ui_confirm_2.vue";
let store = useStore();
let lang = computed(() => {
  return store.state.lang;
});
let userInfo = computed(() => {
  return store.state.userInfo;
});
let userU = ref();
// 查詢U幣餘額
const queryFinance = () => {
  common.queryFinance().then((r) => {
    if (r.data.code == 0 && r.data.infos.length >= 0) {
      const userFinanceInfo = r.data.infos[0][userInfo.value.uid];
      console.log("--lb", userFinanceInfo);
      if (userFinanceInfo) {
        userU.value =
          common.getValueByFinanceType(common.financeType.diamond, userFinanceInfo) /
          1000;
      }
    }
  });
};

//獲取轉賬手續費
let send_asset_change_rate = ref();
const get_send_asset_change_rate = () => {
  const sendUrl =
    common.userApi.requestUrl + "/misc_api/sys_config/items?names=send_asset_change_rate";
  const sendHeader = common.buildHeaders("form");
  common.showLoading("mall get_send_asset_change_rate");
  axios
    .get(sendUrl, { headers: sendHeader })
    .then((r) => {
      console.log(r);
      if (r.data.code == 0) {
        send_asset_change_rate.value = r.data.items[0];
      }
    })
    .finally(() => {
      common.hideLoading("mall get_send_asset_change_rate");
    });
};
get_send_asset_change_rate();

let toUid = ref();
let toNick = ref();
let toAvatar = ref();
let toNum = ref();
let showTransferTag = ref(false); //顯示確定轉賬框
let confirmAgain = ref(false); //再次向同一個人轉同樣的金額

// 獲取對方信息
const getToUserInfo = () => {
  if (toUid.value) {
    const sendUrl = common.userApi.requestUrl + "/user_api/users?uids=" + toUid.value;
    const sendHeaders = common.buildHeaders("form");
    const sendData = {
      headers: sendHeaders,
    };
    axios.get(sendUrl, sendData).then((r) => {
      console.log(r);
      if (r.data.code == 0) {
        if (r.data.uinfos[0].uid && r.data.uinfos[0].nick) {
          toNick.value = r.data.uinfos[0].nick;
          toAvatar.value = r.data.uinfos[0].head_url;
        } else {
          toNick.value = null;
          toAvatar.value = null;
          if (lang == "en") {
            toastr["error"]("User Not Found");
          } else {
            toastr["error"]("無此用戶");
          }
        }
      } else {
        if (lang == "en") {
          toastr["error"]("Unable to Get User Info");
        } else {
          toastr["error"]("獲取用戶信息失敗");
        }
      }
    });
  }
};

//轉賬
/* 
# 送財產給指定的人 
/finance_api/assets/:uid 
 */
const transfer = (tag = 0) => {
  const sendUrl = common.userApi.requestUrl + "/finance_api/assets/" + toUid.value;
  const sendData = common.buildSendData({
    asset_type: common.financeType.diamond,
    asset_value: toNum.value * 1000,
    confirm_flag: tag,
  });
  common.showLoading("u transfer");
  axios
    .put(sendUrl, sendData, { header: common.buildHeaders("form") })
    .then((r) => {
      console.log(r);
      if (r.data.code == 0) {
        toastr["success"](r.data.msg);
        confirmAgain.value = false;
        showTransferTag.value = false;
        queryFinance();
      } else if (r.data.code == 11) {
        confirmAgain.value = true;
        showTransferTag.value = true;
        toastr["warning"](r.data.msg);
      } else {
        toastr["error"](r.data.msg);
      }
    })
    .finally(() => {
      common.hideLoading("u transfer");
    });
};

onMounted(() => {
  queryFinance();
});
</script>
<style lang="scss" scoped>
.transfer {
  color: #fff;
  .finance {
    padding: 0.35rem 0.6rem !important;
  }
  .touser {
    display: flex;
    align-items: center;
    margin-bottom: 0.3rem;
  }
  .avatar {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    overflow: hidden;
    display: block;
    background-color: #272b49;
    margin: 0 0.1rem 0 1.26rem;
  }
  input {
    display: block;
    outline: none;
    width: 6rem;
    border: 1px solid #34395b;
    background-color: transparent;
    border-radius: 0.1rem;
    padding: 0.13rem 0.2rem;
    font-size: 0.16rem;
    font-weight: bold;
    line-height: 0.22rem;
    color: #fff;
    box-sizing: border-box;

    &:hover {
      border-color: #828282;
    }
  }

  input[data-type="password"] {
    padding-right: 0.64rem;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
  .btnbox {
    padding: 0 0.6rem;
  }
  .confirmbox {
    .again {
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.5);
      padding: 0.5rem;
      box-sizing: border-box;
      .box {
        position: absolute;
        width: 5rem;
        height: auto;
        line-height: 1.8;
        background-color: #fff;
        padding: 0.2rem;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 0.05rem;
        box-shadow: 0 0 0.1rem #aaa;
      }
      .btn {
        padding: 0 0.3rem;
        text-align: right;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        span {
          display: inline-block;
          width: 0.86rem;
          height: 0.38rem;
          background-color: #bdbdbd;
          border-radius: 5px;
          font-size: 0.18rem;
          color: rgba(255, 255, 255, 0.8);
          text-align: center;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;

          &:hover {
            background-color: #828282;
          }

          &.confirm {
            background: var(--linearGradientRed);
            margin-left: 0.2rem;
            color: #fff;
            width: 1.3rem;

            &:hover {
              background: var(--linearGradientRedHover);
            }
          }
        }
      }
    }
    .content {
      padding: 0.2rem !important;
    }
    li {
      font-size: 0.16rem !important;
      display: flex;
      margin-bottom: 0.3rem;
      align-items: center;
      .avatar {
        margin: 0;
        margin-right: 0.1rem;
      }
      .val {
        display: flex;
        align-items: center;
      }
    }
    .tips {
      color: red;
    }
  }
}
</style>
