<template>
  <div class="template u_my_wallet">
    <div class="main" v-show="!exchangeType">
      <!--用户信息-->
      <div class="h block">
        <div class="left">
          <div class="img">
            <img :src="userInfo.head_url" alt="" class="avatar" />
          </div>
          <div class="nick">{{ userInfo.nick }}</div>
          <div class="id">
            <span>ID</span>{{ userInfo.uid
            }}<svg @click="copyLinks" width="19" height="18" viewBox="0 0 19 18" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M12.5 12V13.5V15H11H5H3.5V13.5V7.5V6H5H6.5V7.5H5V13.5H11V12H12.5Z" fill="white" />
              <rect x="7.25" y="3.75" width="7.5" height="7.5" stroke="white" stroke-width="1.5" />
            </svg>
          </div>
        </div>
        <div class="right">
          <div class="mod">
            <h2>{{ lang == "en" ? "Gold Amount" : "金幣數量" }}</h2>
            <div>
              <icon_gold />{{ userGold }}
            </div>
          </div>
          <div class="mod">
            <h2>{{ lang == "en" ? "Silver Amount" : "銀幣數量" }}</h2>
            <div>
              <icon_silver />{{ userSilver }}
            </div>
          </div>
          <div class="mod">
            <h2>{{ lang == "en" ? "Gold Medal Amount" : "金牌數量" }}</h2>
            <div>{{ userGoldMedal }}</div>
          </div>
          <div class="mod">
            <h2>{{ lang == "en" ? "Sliver Medal Amount" : "銀牌數量" }}</h2>
            <div>{{ userSliverMedal }}</div>
          </div>
        </div>
      </div>

      <!--链上钱吧-->
      <WalletOnChain :goldBalance="userGold" />

      <!--金币数量、u币数量-->
      <div class="m">
        <div class="left block">
          <div class="title">
            <icon_red_triangle />{{ lang == "en" ? "Gold Amount" : "金幣數量" }}
          </div>
          <div class="box gold">
            <div class="amount">
              <icon_gold />{{ userGold }}
            </div>
            <div class="btn_box">
              <ui_btn_1 class="btn" :data-exchangeType="1" :width="'1.2rem'" :height="'0.48rem'"
                @click="exchangeType = 1">{{ lang == "en" ? "Buy" : "兌入" }}</ui_btn_1>
              <ui_btn_4 :data-exchangeType="2" :width="'1.2rem'" :hollow="true" :height="'0.48rem'"
                @click="exchangeType = 2">{{ lang == "en" ? "Sell" : "兌出" }}</ui_btn_4>
              <!--
                <ui_btn_4 :data-exchangeType="'ybf'" class="ybf" :width="'1.2rem'" :height="'0.48rem'" :hollow="true"
                @click="exchangeType = 'ybf'"><img :src="cdnUrl + '/web_assets/img/yibifu.logo.png'" alt="" /></ui_btn_4>
              -->
              <ui_btn_4 v-if="userInfo && !userInfo.adv_functions_flag" :width="'1.2rem'" :height="'0.48rem'"
                :hollow="true" @click="toOther"><span class="other">
                  <img class="logo" :src="common.cdnArray[0] + '/web_assets/img/otherpay/zfb.png'" alt="" /><img
                    class="logo" :src="common.cdnArray[0] + '/web_assets/img/otherpay/wx.png'" alt="" />其他支付
                </span></ui_btn_4>
            </div>
          </div>
        </div>
        <div class="right block">
          <div class="title">
            <icon_red_triangle />{{ lang == "en" ? "GU Amount" : "U幣數量" }}
          </div>
          <div class="box u">
            <div class="amount">
              <icon_u />{{ userU }}
            </div>
            <div class="btn_box">
              <ui_btn_1 class="btn" :data-exchangeType="3" @click="exchangeType = 3" :width="'1.2rem'"
                :height="'0.48rem'">{{ lang == "en" ? "Deposit" : "充值" }}</ui_btn_1>
              <ui_btn_4 @click="exchangeType = 4" :width="'1.2rem'" :height="'0.48rem'" :hollow="true">{{ lang == "en" ?
                "Withdraw" : "提現" }}</ui_btn_4>
              <ui_btn_4 v-if="userInfo && !userInfo.adv_functions_flag" @click="toUnionpay" :width="'1.2rem'"
                :height="'0.48rem'" :hollow="true" :padding="'0 .1rem'" class="unionpay"><img
                  src="../../assets/img/unionpay.png" />{{ lang == "en" ? "bank card Withdraw" : "銀行卡提現" }}</ui_btn_4>
            </div>
          </div>
        </div>
      </div>

      <!--交易记录-->
      <div class="list_table block">
        <div class="t">
          <icon_red_triangle />{{ lang == "en" ? "Transaction List" : "交易歷史記錄" }}
        </div>
        <template
          v-if="ingOrderList && (ingOrderList[0].length > 0 || ingOrderList[1].length > 0 || ingOrderList[2].length > 0)">
          <dl v-if="userInfo && ingOrderList && ingOrderList.length > 0" class="onlypc">
            <dt>
              <span class="date">{{ lang == "en" ? "Submission Time" : "提交時間" }}</span>
              <span>{{ lang == "en" ? "Type" : "類型" }}</span>
              <span class="status">{{ lang == "en" ? "Status" : "狀態" }}</span>
            </dt>
            <dd @click="showDetail(item, 3, 'excharge')" v-if="ingOrderList[0] && ingOrderList[0].length > 0"
              v-for="(item, index) in ingOrderList[0]" :key="index">
              <span class="date">{{ formatTime(item.add_time) }}</span>
              <span>{{ lang == "en" ? "Deposit" : "充值" }}</span>
              <span :class="{
                status: true,
                orange: item.status == 0,
                green: item.status == 1,
                red: item.status == 2,
              }">{{ formatChainStatus(item.status) }}</span>
            </dd>
            <dd @click="showDetail(item, 4, 'withdraw')" v-if="ingOrderList[1] && ingOrderList[1].length > 0"
              v-for="(item, index) in ingOrderList[1]" :key="index">
              <span class="date">{{ formatTime(item.add_time) }}</span>
              <span>{{ lang == "en" ? "Withdraw" : "提現" }}</span>
              <span :class="{
                status: true,
                orange: item.status == 0,
                green: item.status == 1,
                red: item.status == 2,
              }">{{ formatChainStatus(item.status) }}</span>
            </dd>
            <dd @click="showDetail(item, 4, 'withdraw')" v-if="ingOrderList[2] && ingOrderList[2].length > 0"
              v-for="(item, index) in ingOrderList[2]" :key="index">
              <span class="date">{{ formatTime(item.add_time) }}</span>
              <span>{{ lang == "en" ? "Withdraw" : "提現" }}</span>
              <span :class="{
                status: true,
                orange: item.status == 0,
                green: item.status == 1,
                red: item.status == 2,
              }">{{ formatChainStatus(item.status) }}</span>
            </dd>
          </dl>
        </template>
        <div class="null" v-else>{{ lang == 'en' ? 'Nothing Yet' : '暫無數據' }}</div>
        <dl v-if="userInfo && ingOrderList && ingOrderList.length > 0" class="onlymobile">
          <dd @click="showDetail(item, 3, 'excharge')" v-if="ingOrderList[0] && ingOrderList[0].length > 0"
            v-for="(item, index) in ingOrderList[0]" :key="index">
            <span>{{ lang == "en" ? "Deposit" : "充值" }}</span>
            <span class="val">{{ item.asset_count }}
              <icon_u />
              <icon_back />
            </span>
            <span class="date">{{ formatTime(item.add_time) }}</span>
            <span :class="{
              status: true,
              orange: item.status == 0,
              green: item.status == 1,
              red: item.status == 2,
            }">{{ formatChainStatus(item.status) }}</span>
          </dd>
          <dd @click="showDetail(item, 4, 'withdraw')" v-if="ingOrderList[1] && ingOrderList[1].length > 0"
            v-for="(item, index) in ingOrderList[1]" :key="index">
            <span>{{ lang == "en" ? "Withdraw" : "提現" }}</span>
            <span class="val">{{ item.asset_count }}
              <icon_u />
              <icon_back />
            </span>
            <span class="date">{{ formatTime(item.add_time) }}</span>
            <span :class="{
              status: true,
              orange: item.status == 0,
              green: item.status == 1,
              red: item.status == 2,
            }">{{ formatChainStatus(item.status) }}</span>
          </dd>
          <dd @click="showDetail(item, 4, 'withdraw')" v-if="ingOrderList[2] && ingOrderList[2].length > 0"
            v-for="(item, index) in ingOrderList[2]" :key="index">
            <span>{{ lang == "en" ? "Withdraw" : "提現" }}</span>
            <span class="val">{{ item.asset_count }}
              <icon_u />
              <icon_back />
            </span>
            <span class="date">{{ formatTime(item.add_time) }}</span>
            <span :class="{
              status: true,
              orange: item.status == 0,
              green: item.status == 1,
              red: item.status == 2,
            }">{{ formatChainStatus(item.status) }}</span>
          </dd>
        </dl>
        <p style="margin-left: 0.6rem" v-else>
          {{ lang == "en" ? "Nothing Yet" : "暫無訂單" }}
        </p>
      </div>
    </div>
    <ybf v-if="exchangeType == 'ybf'" v-model="exchangeType"></ybf>
    <Detail v-else-if="exchangeType == 5 && detailItemTypeStr == 4" :data-type="detailItemTypeStr"
      :modelValue="exchangeType" :type="detailItemTypeStr" :detailItem="detailItem"
      @update:modelValue="(newVal) => (exchangeType = newVal)"></Detail>
    <Exchange_v2 v-else-if="exchangeType" :gold="userGold" :u="userU" :modelValue="exchangeType"
      @update:modelValue="(newVal) => (exchangeType = newVal)" />
  </div>
</template>
<script setup>
import { useStore } from "vuex";
import common from "../../utils/common";
import { computed, watch, ref, onMounted, onUnmounted, inject, provide } from "vue";
import axios from "axios";
import ui_btn_1 from "../ui/ui_btn_1.vue";
import ui_btn_4 from "../ui/ui_btn_4.vue";
import icon_gold from "@/components/ui/icon/icon_gold.vue";
import icon_silver from "@/components/ui/icon/icon_silver.vue";
import icon_u from "@/components/ui/icon/icon_u.vue";
// import Exchange from "./Exchange.vue";
import Exchange_v2 from "./Exchange_v2.vue";
import Detail from "./Detail.vue";
import icon_back from "../ui/icon/icon_back.vue";
import { useRouter } from "vue-router";
// import Detail_deposit from "./Detail_deposit.vue";
import ybf from "../../views/ybf/ybf.vue";
import icon_red_triangle from "@/components/ui/icon/icon_red_triangle.vue";
import WalletOnChain from "./wallet_on_chain/WalletOnChain.vue";

let formatChainStatus = inject("formatChainStatus");

let store = useStore();
let router = useRouter();
let cdnUrl = computed(() => {
  return store.state.cdnUrl;
});
let lang = computed(() => {
  return store.state.lang;
});
let userInfo = computed(() => {
  return store.state.userInfo;
});

//是否顯示相應兌換頁。1金幣兌入；2金幣兌出；3U幣充值；4U幣提現；5交易訂單詳情
let exchangeType = ref(false);

//從兌換頁返回時，刷新數據
watch(exchangeType, (n, o) => {
  if (!n) {
    queryFinance();
    getOrders();
  }
});

const formatTime = (d) => {
  return common.formatDateTime(d);
};

// 用戶的金幣和U幣
let userGold = ref();
let userSilver = ref();
let userGoldMedal = ref();
let userSliverMedal = ref();
let userU = ref();
const queryFinance = () => {
  common.queryFinance().then((r) => {
    if (r.data.code == 0 && r.data.infos.length >= 0) {
      const userFinanceInfo = r.data.infos[0][userInfo.value.uid];
      console.log("--lb", userFinanceInfo);
      if (userFinanceInfo) {
        userGold.value = common.getValueByFinanceType(
          common.financeType.gold,
          userFinanceInfo
        );
        userSilver.value = common.getValueByFinanceType(
          common.financeType.silver,
          userFinanceInfo
        );
        userGoldMedal.value = common.getValueByFinanceType(
          common.financeType.goldMedal,
          userFinanceInfo
        );
        userSliverMedal.value = common.getValueByFinanceType(
          common.financeType.silverMedal,
          userFinanceInfo
        );
        userU.value =
          common.getValueByFinanceType(common.financeType.diamond, userFinanceInfo) /
          1000;
      }
    }
  });
};
provide("queryFinance", queryFinance);

// 進行中的用戶的充值和提現訂單
let ingOrderList = ref();
const getExchargeOrderList = () => {
  return axios.get(
    common.userApi.requestUrl +
    "/finance_api/bwallet/excharge_orders?status=0&offset=0&count=50"
  );
};
const getWithdrawOrderList = () => {
  return axios.get(
    common.userApi.requestUrl +
    "/finance_api/bwallet/withdraw_orders?status=0&offset=0&count=50"
  );
};
const getWithdrawTryOrderList = () => {
  return axios.get(
    common.userApi.requestUrl +
    "/finance_api/bwallet/withdraw_orders?status=3&offset=0&count=50"
  );
};
const getOrders = () => {
  axios
    .all([getExchargeOrderList(), getWithdrawOrderList(), getWithdrawTryOrderList()])
    .then(
      axios.spread((r1, r2, r3) => {
        if (r1 && r2 && r3) {
          const res1 = r1.data.infos;
          const res2 = r2.data.infos;
          const res3 = r3.data.infos;
          ingOrderList.value = [res1, res2, res3];
          console.log(ingOrderList.value);
        }
      })
    );
};

// 顯示訂單詳情
const showDetail = (item, typeStr, type) => {
  localStorage.setItem("uDetailItem", JSON.stringify(item));
  router.push("/u/detail?type=" + type + "&data=item");
  // exchangeType.value = 5;
  // detailItem.value = item;
  // detailItemTypeStr.value = typeStr;
};
// 訂單詳情Item
let detailItem = ref();
let detailItemTypeStr = ref();

// 用戶改變時，刷新頁面數據
watch(
  () => userInfo.value,
  (n, o) => {
    if (n && n.uid) {
      queryFinance();
      getOrders();
    }
  }
);

const copyLinks = async () => {
  await common.toClipboard(userInfo.value.uid);
  toastr["success"]("Copied successfully!!");
};

const toOther = () => {
  let toOtherUrl = "http://13.208.140.42:6345/pay?uid=" + userInfo.value.uid;
  if (common.isProduction) {
    toOtherUrl = "https://pay.blockwar.io/recharge?uid=" + userInfo.value.uid;
  }
  window.open(toOtherUrl);
};
const toUnionpay = () => {
  // appid uid platform  device_token token   app_type   bank_code  bankcard_account_no bankcard_account_name amount
  let toOtherUrl = "http://13.208.140.42:6345/cash-out";
  if (location.hostname == 'localhost') {
    toOtherUrl = "/otherpay/unionpay.html";
  }
  else if (common.isProduction) {
    toOtherUrl = "https://pay.blockwar.io/cash-out";
  }
  toOtherUrl += "?uid=" + userInfo.value.uid + '&device_token=' + localStorage.visitorId + "&token=" + userInfo.value.token + "&apiUrl=" + localStorage.getItem('apiUrl');
  window.open(toOtherUrl);
};

onMounted(() => {
  if (userInfo.value) {
    queryFinance();
    getOrders();

    // 根據地址欄顯示對應的充值組件
    // 充值U
    if (location.hash == "#u") {
      exchangeType.value = 3;
    }
    // 充值金幣
    else if (location.hash == "#g") {
      exchangeType.value = 1;
    }
  }
});

//定时刷新用户数据
const updateFinance = () => {
  common.queryFinance(false).then((r) => {
    if (r.data.code == 0 && r.data.infos.length >= 0) {
      const userFinanceInfo = r.data.infos[0][userInfo.value.uid];
      console.log("--lb", userFinanceInfo);
      if (userFinanceInfo) {
        userGold.value = common.getValueByFinanceType(
          common.financeType.gold,
          userFinanceInfo
        );
        userSilver.value = common.getValueByFinanceType(
          common.financeType.silver,
          userFinanceInfo
        );
        userGoldMedal.value = common.getValueByFinanceType(
          common.financeType.goldMedal,
          userFinanceInfo
        );
        userSliverMedal.value = common.getValueByFinanceType(
          common.financeType.silverMedal,
          userFinanceInfo
        );
        userU.value =
          common.getValueByFinanceType(common.financeType.diamond, userFinanceInfo) /
          1000;
      }
    }
  });
};

let timer = null;
onMounted(() => {
  timer = setInterval(updateFinance, 30000);
})

onUnmounted(() => {
  clearInterval(timer);
})

</script>
<style lang="scss">
.null {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: .12rem;
  cursor: default !important;
}
</style>
<style lang="scss" src="./MyWallet.scss"></style>
