<template>
    <div class="template icon_checkbox">
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="13" height="13" rx="0.5" stroke="white"/>
            </svg>  
            <svg class="selected" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="14" height="14" rx="1" fill="#EF4F55"/>
                <path d="M11 3.99995L5.5 9.50005L3 7.00011" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
    </div>
                  
</template>
<style lang="scss" scoped>
    svg{
        width: 0.14rem;
        height: 0.14rem;
        rect{
            width: 0.13rem;
            height: 0.13rem;
        }
    }
    .selected{
        display: none;
        rect{
            width: 0.14rem;
            height: 0.14rem;
        }
    }
</style>