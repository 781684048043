<template>
  <svg
    class="icon_red_triangle"
    width="9"
    height="9"
    viewBox="0 0 9 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 1.41421C9 0.523309 7.92286 0.077142 7.29289 0.707107L0.707105 7.2929C0.0771399 7.92286 0.523309 9 1.41421 9H8C8.55228 9 9 8.55229 9 8V1.41421Z"
      fill="#EF4F55"
    />
  </svg>
</template>
