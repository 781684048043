<template>
    <svg class="icon_filter" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.42591 3H12.5741C12.6566 3 12.7373 3.02543 12.8065 3.07319C12.8756 3.12095 12.9302 3.189 12.9636 3.26905C12.997 3.3491 13.0077 3.43772 12.9945 3.52413C12.9813 3.61054 12.9447 3.69102 12.8892 3.75579L9.38851 7.84104C9.31723 7.92421 9.27774 8.03258 9.27774 8.14498V11.3432C9.27774 11.4176 9.26043 11.4909 9.22735 11.5564C9.19426 11.622 9.14642 11.6779 9.08808 11.7192L7.38443 12.9241C7.32028 12.9695 7.24574 12.9955 7.16874 12.9995C7.09174 13.0034 7.01517 12.9851 6.9472 12.9465C6.87923 12.9079 6.82241 12.8505 6.78279 12.7803C6.74318 12.7102 6.72226 12.6299 6.72226 12.5482V8.14498C6.72226 8.03258 6.68277 7.92421 6.61149 7.84104L3.11076 3.75579C3.05526 3.69102 3.01869 3.61054 3.00549 3.52413C2.99229 3.43772 3.00303 3.3491 3.03641 3.26905C3.06979 3.189 3.12437 3.12095 3.19352 3.07319C3.26267 3.02543 3.3434 3 3.42591 3Z" fill="white"/>
        </svg>        
</template>
<style lang="scss" scoped>
.icon_filter{
    width: 0.16rem;
    height: 0.16rem;
    cursor: pointer;
    &:hover{
        fill: #EF4F55;
        path{
            fill: #EF4F55;
        }
    }
}
</style>