<template>
  <div class="template my_wallet_log">
    <div class="caption disselect" v-if="!exchangeType">
      <ul class="tab">
        <li
          :data-tabindex="tabIndex"
          :class="{ on: tabIndex == 3 }"
          @click="tabIndex = 3"
        >
          <span>{{ lang == "en" ? "Deposit" : "充值" }}</span>
        </li>
        <li
          :data-tabindex="tabIndex"
          :class="{ on: tabIndex == 4 }"
          @click="tabIndex = 4"
        >
          <span>{{ lang == "en" ? "Withdraw" : "提現" }}</span>
        </li>
        <li
          :data-tabindex="tabIndex"
          :class="{ on: tabIndex == 1 }"
          @click="tabIndex = 1"
        >
          <span>{{ lang == "en" ? "Exchange" : "兌換" }}</span>
        </li>
        <li
          :data-tabindex="tabIndex"
          :class="{ on: tabIndex == 5 }"
          @click="tabIndex = 5"
        >
          <span>{{ lang == "en" ? "Transfer" : "轉賬" }}</span>
        </li>
      </ul>
    </div>
    <div class="list_table" v-if="!exchangeType">
      <p class="null" v-if="!orderList">{{ lang == "en" ? "Loading" : "加載中..." }}</p>
      <p class="null" v-else-if="orderList.length == 0">
        {{ lang == "en" ? "Nothing Yet" : "暫無數據" }}
      </p>
      <dl v-else-if="tabIndex == 1">
        <dt>
          <span class="seqnum">{{ lang == "en" ? "Transaction ID" : "訂單編號" }}</span>
          <span class="type">
            <ui_filter
              :modelValue="exchange_filterType"
              @update:modelValue="
                (v) => {
                  exchange_filterType = v;
                }
              "
              :optionList="exchange_options"
            />
          </span>
          <span class="count">{{ lang == "en" ? "Amount" : "金額" }}</span>
          <span class="count">{{ lang == "en" ? "Fee" : "手續費" }}</span>
          <span class="date">{{ lang == "en" ? "Submission Time" : "提交時間" }}</span>
          <span class="status">{{ lang == "en" ? "Status" : "狀態" }}</span>
        </dt>
        <template
          v-for="(item, index) in orderList"
          :key="index"
          v-if="orderList.length > 0"
        >
          <dd
            v-if="item.op_value > 0"
            :class="{ default: true, hover: item.op == 65 }"
            @click="log(item)"
            v-show="exchange_filterType == 0 || exchange_filterType == 1"
          >
            <span class="seqnum">{{ item.seqnum }}</span>
            <span class="type">{{ lang == "en" ? "Buy" : "兌入" }}</span>
            <span class="count">
              <i v-html="formatAssetInfos(item.asset_type, item.op_value)"></i>
              <img
                class="ybf"
                v-if="item.op == 65"
                :src="cdnUrl + '/web_assets/img/yibifu.logo.png'"
                alt=""
              />
              <!-- {{ item.op_value }} -->
            </span>
            <span class="count">{{
              item.asset_type == 1 ? item.pay_charge / 1000 : item.pay_charge
            }}</span>
            <span class="date">{{ formatTime(item.add_time) }}</span>
            <span class="status green">{{ lang == "en" ? "Completed" : "完成" }}</span>
          </dd>
          <dd
            v-else
            class="default"
            @click="log(item)"
            v-show="exchange_filterType == 0 || exchange_filterType == 2"
          >
            <span class="seqnum">{{ item.seqnum }}</span>
            <span class="type">{{ lang == "en" ? "Sell" : "兌出" }}</span>
            <span class="count">
              <i v-html="formatAssetInfos(item.asset_type, item.op_value)"></i>
              <!-- {{ item.op_value }} -->
            </span>
            <span class="count"
              ><i class="onlymobile fee">{{ lang == "en" ? "Fee" : "手續費" }}</i
              >{{ item.asset_type == 1 ? item.pay_charge / 1000 : item.pay_charge }}</span
            >
            <span class="date">{{ formatTime(item.add_time) }}</span>
            <span class="status green">{{ lang == "en" ? "Completed" : "完成" }}</span>
          </dd>
        </template>
      </dl>
      <dl v-else-if="tabIndex == 5" id="transfer">
        <dt>
          <span class="type">UID</span>
          <span class="type">
            <ui_filter
              :modelValue="transfer_filterType"
              @update:modelValue="
                (v) => {
                  transfer_filterType = v;
                }
              "
              :optionList="transfer_options"
            />
          </span>
          <span class="count">{{ lang == "en" ? "Amount" : "轉賬金額" }}</span>
          <span class="date">{{ lang == "en" ? "Submission Time" : "轉賬時間" }}</span>
          <span class="status">{{ lang == "en" ? "Balance" : "餘額" }}</span>
        </dt>
        <template
          v-for="(item, index) in orderList"
          :key="index"
          v-if="orderList.length > 0"
        >
          <dd
            v-if="item.op_value > 0"
            class="default"
            v-show="transfer_filterType == 0 || transfer_filterType == 1"
          >
            <span class="type"
              ><span class="onlymobile">UID: </span>{{ item.peer_uid }}</span
            >
            <span class="type">
              <span class="onlymobile">{{ lang == "en" ? "Type" : "類型" }}:</span>
              {{ lang == "en" ? "Inbound" : "轉入" }}
            </span>
            <span class="count">
              <span class="onlymobile">{{ lang == "en" ? "Amount" : "轉賬金額" }}:</span>
              <i class="red">+</i>
              <i
                class="red"
                v-html="formatAssetInfos(item.asset_type, item.op_value)"
              ></i>
            </span>
            <span class="date">
              <span class="onlymobile"
                >{{ lang == "en" ? "Submission Time" : "轉賬時間" }}:
                {{ formatTime(item.add_time) }}</span
              >
            </span>
            <span class="status">
              <span class="onlymobile">{{ lang == "en" ? "Balance" : "餘額" }}: </span>
              {{ item.current_value / 1000 }}
              <Icon_u class="icon" />
            </span>
          </dd>
          <dd
            v-else
            class="default"
            v-show="transfer_filterType == 0 || transfer_filterType == 2"
          >
            <span class="type"
              ><span class="onlymobile">UID:</span> {{ item.peer_uid }}</span
            >
            <span class="type">
              <span class="onlymobile">{{ lang == "en" ? "Type" : "類型" }}: </span
              >{{ lang == "en" ? "Outbound" : "轉出" }}
            </span>
            <span class="count">
              <span class="onlymobile">{{ lang == "en" ? "Amount" : "轉賬金額" }}: </span>
              <i>-</i>
              <i
                class="icobox"
                v-html="formatAssetInfos(item.asset_type, item.op_value)"
              ></i>
            </span>
            <span class="date">
              <span class="onlymobile"
                >{{ lang == "en" ? "Submission Time" : "轉賬時間" }}:</span
              >
              {{ formatTime(item.add_time) }}
            </span>
            <span class="status">
              <span class="onlymobile">{{ lang == "en" ? "Balance" : "餘額" }}: </span>
              {{ item.current_value / 1000 }}
              <Icon_u class="icon" />
            </span>
          </dd>
        </template>
      </dl>
      <dl v-else :data-tabindex="tabIndex">
        <dt>
          <span class="date">{{ lang == "en" ? "Submission Time" : "提交時間" }}</span>
          <span class="count">{{ lang == "en" ? "Amount" : "金額" }}</span>
          <span>{{ lang == "en" ? "Fee" : "手續費" }}</span>
          <span class="status">{{ lang == "en" ? "Status" : "狀態" }}</span>
        </dt>
        <dd
          @click="showDetail(item, tabIndex)"
          v-if="orderList.length > 0"
          v-for="(item, index) in orderList"
          :key="index"
        >
          <span class="date">{{ formatTime(item.add_time) }}</span>
          <span
            class="count"
            :data-shortcount="item.short_count"
            :data-assetcount="item.asset_count"
            >{{ tabIndex == 3 ? item.short_count : item.asset_count / 1000 }}
            <Icon_u class="icon" /><icon_back class="onlymobile" />
          </span>
          <span>
            <span class="onlymobile">{{ lang == "en" ? "Free" : "手續費" }} </span
            >&ensp;{{ item.charge / 1000 }}
            <Icon_u class="icon" />
          </span>
          <span
            :class="{
              status: true,
              orange: item.status == 0,
              green: item.status == 1,
              red: item.status == 2,
            }"
            >{{ formatChainStatus(item.status) }}</span
          >
        </dd>
      </dl>
    </div>
    <!-- <Detail_deposit
      :data-tabIndex="tabIndex"
      v-if="exchangeType == 5 && tabIndex == 3"
      :modelValue="exchangeType"
      :type="tabIndex"
      :detailItem="detailItem"
      @update:modelValue="(newVal) => (exchangeType = newVal)"
    ></Detail_deposit> -->
    <Detail
      :data-tabIndex="tabIndex"
      v-else-if="exchangeType == 5"
      :modelValue="exchangeType"
      :type="tabIndex"
      :detailItem="detailItem"
      @update:modelValue="(newVal) => (exchangeType = newVal)"
    ></Detail>
  </div>
</template>
<script setup>
import axios from "axios";
import { onMounted, ref, watch, inject, computed } from "vue";
import common from "../../utils/common.js";
import Detail from "./Detail.vue";
import Icon_u from "../ui/icon/icon_u.vue";
import { useStore } from "vuex";
import ui_filter from "../ui/ui_filter.vue";
import icon_back from "../ui/icon/icon_back.vue";
// import Detail_deposit from "./Detail_deposit.vue";
import { useRouter } from "vue-router";
let store = useStore();
let router = useRouter();
let cdnUrl = computed(() => {
  return store.state.cdnUrl;
});
let lang = computed(() => {
  return store.state.lang;
});

let transfer_filterType = ref(0);
let transfer_options = ref([
  {
    value: 0,
    name: "全部",
  },
  {
    value: 1,
    name: lang.value == "en" ? "Inbound" : "轉入",
  },
  {
    value: 2,
    name: lang.value == "en" ? "Outbound" : "轉出",
  },
]);
let exchange_filterType = ref(0);
let exchange_options = ref([
  {
    value: 0,
    name: "全部",
  },
  {
    value: 1,
    name: lang.value == "en" ? "Buy" : "兌入",
  },
  {
    value: 2,
    name: lang.value == "en" ? "Sell" : "兌出",
  },
]);

let formatChainStatus = inject("formatChainStatus");
const formatAssetInfos = (type, val) => {
  if (type == 1) {
    (type = "u"), (val = val / 1000);
  }
  const a = [{ type: type, value: Math.abs(val) }];
  return common.formatAssetInfos(a);
};

let tabIndex = ref(3);
let orderList = ref();
const getDataList = () => {
  orderList.value = null;
  let sendUrl = null;
  if (tabIndex.value == 3) {
    sendUrl =
      common.userApi.requestUrl +
      "/finance_api/bwallet/excharge_orders?offset=0&count=50";
    // sendUrl = common.userApi.requestUrl + '/finance_api/transaction_records?offset=0&count=50&ops=61';
  } else if (tabIndex.value == 4) {
    sendUrl =
      common.userApi.requestUrl +
      "/finance_api/bwallet/withdraw_orders?offset=0&count=50";
    // sendUrl = common.userApi.requestUrl + '/finance_api/transaction_records?offset=0&count=50&ops=62';
  } else if (tabIndex.value == 1) {
    sendUrl =
      common.userApi.requestUrl +
      "/finance_api/transaction_records?offset=0&count=50&ops=12,65";
  } else if (tabIndex.value == 5) {
    sendUrl = common.userApi.requestUrl + `/finance_api/transaction_records?ops=4,5`;
  }
  if (sendUrl) {
    common.showLoading("u getDataList");
    axios.get(sendUrl).then((r) => {
      common.hideLoading("u getDataList");
      if (r.data.code == 0) {
        // 轉賬記錄
        if (tabIndex.value == 5) {
          for (let i = 0; i < r.data.records.length; i++) {
            if (r.data.records[i].additional_data) {
              let additional_data = r.data.records[i].additional_data;
              if (typeof additional_data == "string") {
                additional_data = JSON.parse(additional_data);
              }
              for (let k in additional_data) {
                r.data.records[i][k] = additional_data[k];
              }
            }
          }
          orderList.value = r.data.records;
        }
        // 兌換類記錄（ops==12）不能直接解構additional_data，否則訂單號會被替換
        else if (tabIndex.value == 3 || tabIndex.value == 4) {
          for (let i = 0; i < r.data.infos.length; i++) {
            if (r.data.infos[i].additional_data) {
              let additional_data = r.data.infos[i].additional_data;
              if (typeof r.data.infos[i].additional_data == "string") {
                additional_data = JSON.parse(additional_data);
              }
              for (let k in additional_data) {
                r.data.infos[i][k] = additional_data[k];
              }
            }
          }
          orderList.value = r.data.infos;
        } else {
          orderList.value = r.data.records;
        }
      } else {
        toastr["error"](r.data.msg);
        console.error(r.data.msg);
      }
    });
  }
};

// 切換tab時切換數據
watch(tabIndex, (n, o) => {
  console.log(n, o, tabIndex.value);
  getDataList();
});

const formatTime = (d) => {
  return common.formatDateTime(d);
};

// 顯示訂單詳情
let detailItem = ref();
let exchangeType = ref(null);
const showDetail = (item, type) => {
  let TYPE = type == 3 ? "excharge" : "withdraw";
  localStorage.setItem("uDetailItem", JSON.stringify(item));
  router.push("/u/detail?type=" + TYPE + "&data=item");
};

const log = (item) => {
  console.log(item);
  let sq = JSON.parse(item.additional_data).seqnum;
  console.log(sq);
  // 查詢相關訂單
  if (item.op == 65) {
    const deposit_order_id = JSON.parse(item.additional_data).deposit_order_id;
    router.push("/u/detail?type=ybf&order_id=" + deposit_order_id);
  }
};
// 查詢轉賬記錄
/* 
方法:           GET 
參數:           begin_timestamp 開始時間戳以秒為單位(可選)
               end_timestamp 結束時間戳以秒為單位(可選)
               offset 偏移, 如果不填默認為0
               count 本次查詢的數量, 如果不填則為50, 最大不能超過50
               ops 要過濾的操作類型，多個之間以逗號分割
                */
let transferLog = ref();
const getTransferLog = () => {
  const sendUrl = common.userApi.requestUrl + `/finance_api/transaction_records?ops=4,5`;
  axios.get(sendUrl).then((r) => {
    if (r.data.code == 0) {
      transferLog.value = r.data.records;
      for (let i = 0; i < transferLog.value.length; i++) {
        transferLog.value[i].additional_data = JSON.parse(
          transferLog.value[i].additional_data
        );
      }
      console.log(transferLog.value);
    }
  });
};

onMounted(() => {
  getDataList();
});
</script>
<style lang="scss" scoped>
.icon {
  width: 0.16rem;
  height: 0.16rem;
  margin-left: 0.05rem;
}
.null {
  padding: 0.3rem;
}
.tab {
  display: flex;
  align-items: center;
  padding-top: 0.18rem;
  li {
    cursor: pointer;
    text-align: center;
    width: 2rem;
    height: 0.6rem;
    display: flex;
    color: rgba(255, 255, 255, 0.5);
    align-items: center;
    justify-content: center;
    &:hover {
      color: #fff;
    }
    &.on {
      font-weight: bold;
      color: #fff;
      span {
        position: relative;
        &::after {
          content: "";
          position: absolute;
          width: 100%;
          height: 0.03rem;
          background: linear-gradient(83.73deg, #ff8688 0%, #e9373e 99.75%);
          left: 0;
          bottom: 0;
        }
      }
    }
  }
  span {
    min-width: 0.52rem;
    height: 0.6rem;
    font-size: 0.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-transform: capitalize;
    flex-shrink: 0;
  }
}
dd {
  &.hover {
    cursor: pointer;
  }
  .count {
    i {
      font-style: normal;
      :deep(.assets_info) {
        flex-direction: row-reverse;
        i {
          margin-left: 0.02rem;
        }
      }
    }
    img.ybf {
      height: 0.3rem;
      width: auto;
    }
  }
}
dt {
  .type {
    position: relative;
    .ui_filter {
      left: 0.16rem;
      top: 50%;
      transform: translateY(-50%);
      :deep(ul) {
        left: -0.16rem;
        top: 0.38rem;
        padding: 0 0.16rem;
        box-sizing: border-box;
      }
    }
  }
}
</style>
