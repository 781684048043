<script setup>
import { computed } from "vue";
import common from "../../../utils/common";
import { useStore } from "vuex";
import list_table_container from "./ListTableContainer";
import { formatAddress, createAddressLink, WALLET_TOKEN_TYPE, fromWei, createHashLink } from "../../../utils/walletTools";
import icon_trx from "@/components/ui/icon/icon_trx";
import icon_usdt from '@/components/ui/icon/icon_usdt';

defineProps(["infos"]);
const store = useStore();
const langs = computed(() => {
  if (store.state.lang === "en") {
    return {
      titles: ["Time", "Recipient Address", "Amount", "State", "Order ID", "Transaction Hash"],
      status: ["Pending...", "Under review...", "Approved", "Review failed", "Success", "Failed", "Confirmation required", "unknown error"],
    }
  } else {
    return {
      titles: ["時間", "接收方地址", "金額", "狀態", "訂單號", "交易哈希"],
      status: ["待處理...", "稽核中...", "審核通過", "稽核沒通過", "成功", "失敗", "需要確認", "未知錯誤"],
    }
  }
});

</script>
<style lang="scss" src="./Items.scss"></style>
<template>
  <list_table_container id="list_orders">
    <dt>
      <span>{{ langs.titles[0] }}</span>
      <span>{{ langs.titles[1] }}</span>
      <span>{{ langs.titles[2] }}</span>
      <span>{{ langs.titles[3] }}</span>
      <span class="flex_2">{{ langs.titles[4] }}</span>
      <span>{{ langs.titles[5] }}</span>
    </dt>
    <dd v-if="infos.length > 0" v-for="(item) in infos" :key="item.order_id">
      <span>{{ common.formatDateTime(item.add_time) }}</span>
      <span>
        <a target="_blank" :href="createAddressLink(item.to_chain_account)">{{ formatAddress(item.to_chain_account) }}</a>
      </span>
      <span>
        <icon_trx v-if="item.chain_currency === WALLET_TOKEN_TYPE.TRX" />
        <icon_usdt v-else />
        {{ fromWei(item.count) }}
      </span>
      <!-- 订单状态 -->
      <span class="status_normal" v-if="item.status === 0">{{ langs.status[0] }}</span>
      <span class="status_normal" v-else-if="item.status === 1">{{ langs.status[1] }}</span>
      <span class="status_normal" v-else-if="item.status === 2">{{ langs.status[2] }}</span>
      <span class="status_error" v-else-if="item.status === 3">{{ langs.status[3] }}</span>
      <span class="status_success" v-else-if="item.status === 4">{{ langs.status[4] }}</span>
      <span class="status_error" v-else-if="item.status === 5">{{ langs.status[5] }}</span>
      <span class="status_normal" v-else-if="item.status === 6">{{ langs.status[6] }}</span>
      <span class="status_error" v-else>{{ langs.status[7] }}</span>

      <span class="flex_2">{{ item.order_id }}</span>
      <span>
        <a target="_blank" :href="createHashLink(item.chain_data)">{{ formatAddress(item.chain_data) }}</a>
      </span>
    </dd>
    <dd v-else class="null">{{ "暫無數據" }}</dd>
  </list_table_container>
</template>