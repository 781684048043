<template>
  <div class="loading">
    <div class="flex">
      <img :src="cdnUrl + '/web_assets/img/loading/block_00000.png'" />
      <img :src="cdnUrl + '/web_assets/img/loading/block_00001.png'" /><img
        :src="cdnUrl + '/web_assets/img/loading/block_00002.png'" /><img
        :src="cdnUrl + '/web_assets/img/loading/block_00003.png'" /><img
        :src="cdnUrl + '/web_assets/img/loading/block_00004.png'" /><img
        :src="cdnUrl + '/web_assets/img/loading/block_00005.png'" /><img
        :src="cdnUrl + '/web_assets/img/loading/block_00006.png'" /><img
        :src="cdnUrl + '/web_assets/img/loading/block_00007.png'" /><img
        :src="cdnUrl + '/web_assets/img/loading/block_00008.png'" /><img
        :src="cdnUrl + '/web_assets/img/loading/block_00009.png'" /><img
        :src="cdnUrl + '/web_assets/img/loading/block_00010.png'" /><img
        :src="cdnUrl + '/web_assets/img/loading/block_00011.png'" /><img
        :src="cdnUrl + '/web_assets/img/loading/block_00012.png'" /><img
        :src="cdnUrl + '/web_assets/img/loading/block_00013.png'" /><img
        :src="cdnUrl + '/web_assets/img/loading/block_00014.png'" /><img
        :src="cdnUrl + '/web_assets/img/loading/block_00015.png'" /><img
        :src="cdnUrl + '/web_assets/img/loading/block_00016.png'" /><img
        :src="cdnUrl + '/web_assets/img/loading/block_00017.png'" /><img
        :src="cdnUrl + '/web_assets/img/loading/block_00018.png'" /><img
        :src="cdnUrl + '/web_assets/img/loading/block_00019.png'" /><img
        :src="cdnUrl + '/web_assets/img/loading/block_00020.png'" /><img
        :src="cdnUrl + '/web_assets/img/loading/block_00021.png'" /><img
        :src="cdnUrl + '/web_assets/img/loading/block_00022.png'" /><img
        :src="cdnUrl + '/web_assets/img/loading/block_00023.png'" /><img
        :src="cdnUrl + '/web_assets/img/loading/block_00024.png'" /><img
        :src="cdnUrl + '/web_assets/img/loading/block_00025.png'" /><img
        :src="cdnUrl + '/web_assets/img/loading/block_00026.png'" /><img
        :src="cdnUrl + '/web_assets/img/loading/block_00027.png'" /><img
        :src="cdnUrl + '/web_assets/img/loading/block_00028.png'" /><img
        :src="cdnUrl + '/web_assets/img/loading/block_00029.png'" /><img
        :src="cdnUrl + '/web_assets/img/loading/block_00030.png'" /><img
        :src="cdnUrl + '/web_assets/img/loading/block_00031.png'" /><img
        :src="cdnUrl + '/web_assets/img/loading/block_00032.png'" /><img
        :src="cdnUrl + '/web_assets/img/loading/block_00033.png'" /><img
        :src="cdnUrl + '/web_assets/img/loading/block_00034.png'" /><img
        :src="cdnUrl + '/web_assets/img/loading/block_00035.png'" /><img
        :src="cdnUrl + '/web_assets/img/loading/block_00036.png'" /><img
        :src="cdnUrl + '/web_assets/img/loading/block_00037.png'" /><img
        :src="cdnUrl + '/web_assets/img/loading/block_00038.png'" /><img
        :src="cdnUrl + '/web_assets/img/loading/block_00039.png'" /><img
        :src="cdnUrl + '/web_assets/img/loading/block_00040.png'" /><img
        :src="cdnUrl + '/web_assets/img/loading/block_00041.png'" /><img
        :src="cdnUrl + '/web_assets/img/loading/block_00042.png'" /><img
        :src="cdnUrl + '/web_assets/img/loading/block_00043.png'" /><img
        :src="cdnUrl + '/web_assets/img/loading/block_00044.png'" /><img
        :src="cdnUrl + '/web_assets/img/loading/block_00045.png'" /><img
        :src="cdnUrl + '/web_assets/img/loading/block_00046.png'" /><img
        :src="cdnUrl + '/web_assets/img/loading/block_00047.png'" /><img
        :src="cdnUrl + '/web_assets/img/loading/block_00048.png'" /><img
        :src="cdnUrl + '/web_assets/img/loading/block_00049.png'" />
    </div>
  </div>
</template>
<style scoped lang="scss">
.loading {
  position: absolute;
  top: calc(50% - 0.4rem);
  left: calc(50% - 0.4rem);
  width: 0.8rem;
  height: 0.8rem;
  overflow: hidden;

  .flex {
    height: 40rem;
    display: flex;
    animation: run 1.6s steps(50) 0s infinite;

    img {
      width: 0.8rem;
      height: 0.8rem;
    }
  }
}

@keyframes run {
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(-40rem, 0, 0);
  }
}
</style>
<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
let store = useStore();
let cdnUrl = computed(() => {
  return store.state.cdnUrl;
});
</script>
