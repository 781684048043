<template>
  <span
    :class="{ red: redback, ui_btn_4: true, btn: true }"
    :style="{
      minWidth: width,
      height: height,
      fontSize: fontsize,
      padding: padding,
    }"
    class="ui_btn_4"
  >
    <slot></slot>
  </span>
</template>
<style lang="scss" scoped>
.ui_btn_4 {
  position: relative;
  display: inline-block;
  font-family: "Mortina";
  text-align: center;
  color: #fff;
  cursor: pointer;
  box-sizing: border-box;
  border-radius: 0.06rem;
  border: 1px solid rgba(255, 255, 255, 0.5);
  text-transform: capitalize;
  display: flex !important;
  justify-content: center;
  align-items: center;

  &:hover {
    background: #ef4f55;
  }

  &.red {
    background: var(--linearGradientRed);
    border-width: 0;

    &:hover {
      background: var(--linearGradientRedHover);
    }
  }
}

.ui_btn_2.disclick {
  background-color: #383a48;
  background-image: none;
  cursor: url("https://cdn.blockwar.io/web_assets/img/ico_cursor.png"), auto;
  pointer-events: none;
}

.btn1.border:hover {
  color: #ff8688;
}

.btn1.border::before {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  display: block;
  background-color: #26254c;
  border-radius: 0.1rem;
}
</style>
<script>
import { defineComponent } from "vue";

// import { useStore } from "vuex";
// import { useRouter } from "vue-router";

export default defineComponent({
  name: "ui_btn_2",
  props: {
    width: {
      default: "1.67rem",
    },
    height: {
      default: "0.48rem",
    },
    fontsize: {
      default: "0.18rem",
    },
    redback: {
      default: false,
    },
    padding: {
      default: "0",
    },
  },
  components: {},
  setup(props, ctx) {
    // let router = useRouter();
    //   let store = useStore();
    return {};
  },
});
</script>
